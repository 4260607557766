import React, { useContext, useState } from "react";

import css from "./ProfilePage.module.css";
import { useNavigate } from "react-router-dom";

import { VLQ } from "../../Utils/VLQ";
import { UserContext } from "../../Context/UserContext";
import { updateUserFirebase } from "../../Utils/firebaseUser";
import { Button } from "../Forms/Button";

export const Values = () => {
  const { userData, setUserData } = useContext(UserContext);
  const [index, setIndex] = useState(0);
  const VLQarr = new Array(10);
  for (let i = 0; i < VLQarr.length; i++) {
    VLQarr[i] = new Array(10).fill(false);
  }
  const [VLQCheck, setVLQCheck] = useState(VLQarr);
  const [completeVLQ, setCompleteVLQ] = useState(0);
  const navigate = useNavigate();

  function handleCheckVLQ(row, col) {
    let sum = completeVLQ + 1;
    setCompleteVLQ(sum);
    let array = [...VLQCheck];
    if (array[row].includes(true)) {
      if (array[row][col]) {
        array[row][col] = false;
        setVLQCheck(array);
      } else {
        let position = array[row].indexOf(true);
        array[row][position] = false;
        array[row][col] = !array[row][col];
        setVLQCheck(array);
      }
    } else {
      array[row][col] = !array[row][col];
      setVLQCheck(array);
    }
  }

  function submmit() {
    let VLQReduced = VLQCheck.map((row) => row.indexOf(true));
    const VLQAnswers = new Array(10).fill("");
    for (let index = 0; index < VLQReduced.length; index++) {
      VLQAnswers[index] = VLQ[VLQReduced[index]] ? VLQ[VLQReduced[index]] : "";
    }

    userData.listValues = VLQReduced;

    updateUserFirebase(userData);
    setUserData(userData);
    navigate("/profile");
  }

  if (index === 0) {
    return (
      <div className={css.container}>
        <h3>Valores de Vida</h3>
        <p>
          Abaixo estão listadas áreas da vida que são valorizadas por algumas
          pessoas. Avalie a importância que você dá a cada uma dessas áreas para
          a sua vida, numa escala de 1 a 10.
        </p>
        <p>
          Por exemplo, 1 significa que a área é nada importante e 10 que ela é
          extremamente importante para a sua vida. Nem todas as pessoas irão
          valorizá-las ou avaliá-las da mesma forma.
        </p>
        <p>
          Pense na importância que você atribui a cada uma delas para a sua
          vida, independentemente de sua situação atual. Por exemplo, você pode
          atualmente não estar trabalhando ou não ser pai ou mãe, mas valorizar
          o trabalho ou desejar ser pai ou mãe durante a sua vida.
        </p>
        <p>Clique abaixo para iniciar</p>
        <div className={css.footer}>
          <button className="saveButton" onClick={() => setIndex(1)}>
            Iniciar
          </button>
        </div>
      </div>
    );
  } else if (index === 1) {
    return (
      <div className={css.container}>
        <form>
          {VLQ.map((question, row) => (
            <React.Fragment key={row}>
              <p>
                {row + 1}. {question}
              </p>

              <label className={css.confirmation}>
                Não importante
                <input
                  type="checkbox"
                  checked={VLQCheck[row][0]}
                  onChange={() => {
                    handleCheckVLQ(row, 0);
                  }}
                />
                <span className={css.checkmark}></span>
              </label>
              <label className={css.confirmation}>
                Pouco importante
                <input
                  type="checkbox"
                  checked={VLQCheck[row][1]}
                  onChange={() => {
                    handleCheckVLQ(row, 1);
                  }}
                />
                <span className={css.checkmark}></span>
              </label>
              <label className={css.confirmation}>
                Importante
                <input
                  type="checkbox"
                  checked={VLQCheck[row][2]}
                  onChange={() => {
                    handleCheckVLQ(row, 2);
                  }}
                />
                <span className={css.checkmark}></span>
              </label>
              <label className={css.confirmation}>
                Muito importante
                <input
                  type="checkbox"
                  checked={VLQCheck[row][3]}
                  onChange={() => {
                    handleCheckVLQ(row, 3);
                  }}
                />
                <span className={css.checkmark}></span>
              </label>
              <label className={css.confirmation}>
                Extremamente importante
                <input
                  type="checkbox"
                  checked={VLQCheck[row][4]}
                  onChange={() => {
                    handleCheckVLQ(row, 4);
                  }}
                />
                <span className={css.checkmark}></span>
              </label>
            </React.Fragment>
          ))}
        </form>
        <div className={css.footer}>
          <Button
            onClick={() => {
              submmit();
            }}
          >
            Enviar
          </Button>
        </div>
      </div>
    );
  }
};
