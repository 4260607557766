import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import css from "./ProfilePage.module.css";
import { UserContext } from "../../Context/UserContext";
import { Input } from "../Forms/Input";
import { updateUserFirebase } from "../../Utils/firebaseUser";

export const ListGoals = () => {
  const { userData, setUserData } = useContext(UserContext);
  const [goal1, setGoal1] = useState("");
  const [goal2, setGoal2] = useState("");
  const [goal3, setGoal3] = useState("");
  const [goal4, setGoal4] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (userData) {
      userData.listGoals && setGoal1(userData.listGoals[0]);
      userData.listGoals && setGoal2(userData.listGoals[1]);
      userData.listGoals && setGoal3(userData.listGoals[2]);
      userData.listGoals && setGoal4(userData.listGoals[3]);
    }
  }, [userData]);

  function handleSubmit() {
    const listGoals = [goal1, goal2, goal3, goal4];
    userData.listGoals = listGoals;

    setUserData(userData);
    updateUserFirebase(userData);
    navigate("/profile");
  }

  if (userData) {
    return (
      <div className={css.container}>
        <form>
          <h4>Lista de Metas</h4>
          <Input
            type="text"
            name="goal1"
            placeholder="Meta 1"
            label="Meta 1"
            value={goal1}
            required
            className={css.inputText}
            onChange={(e) => {
              setGoal1(e.target.value);
            }}
            autoComplete="off"
          />
          <Input
            type="text"
            name="goal2"
            placeholder="Meta 2"
            label="Meta 2"
            value={goal2}
            required
            className={css.inputText}
            onChange={(e) => {
              setGoal2(e.target.value);
            }}
            autoComplete="off"
          />
          <Input
            type="text"
            name="goal3"
            placeholder="Meta 3"
            label="Meta 3"
            value={goal3}
            required
            className={css.inputText}
            onChange={(e) => {
              setGoal3(e.target.value);
            }}
            autoComplete="off"
          />
          <Input
            type="text"
            name="goal4"
            placeholder="Meta 4"
            label="Meta 4"
            value={goal4}
            required
            className={css.inputText}
            onChange={(e) => {
              setGoal4(e.target.value);
            }}
            autoComplete="off"
          />
          <div className={css.footer}>
            <button className="saveButton" onClick={() => handleSubmit()}>
              Salvar
            </button>
          </div>
        </form>
      </div>
    );
  } else {
    return <div>Loading...</div>;
  }
};
