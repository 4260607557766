import React from "react";
import { useNavigate } from "react-router-dom";

import css from "./Know.module.css";
import { Head } from "../../Utils/Head";

export const Know = () => {
  const navigate = useNavigate();

  return (
    <div className={css.container}>
      <Head title="Conhecer" description={"Conhecer - Mairini"} />

      <div className={css.cardNone} onClick={() => navigate("/activity")}>
        <h4>Seção 6 - Plano de ação</h4>
      </div>
      <div className={css.cardNone}>
        <i className={`material-icons ${css.sidebarIcon} ${css.active}`}>
          upload
        </i>
      </div>
      <div className={css.cardNone} onClick={() => navigate("/activity")}>
        <h4>Seção 5 - Meus problemas</h4>
      </div>
      <div className={css.cardNone}>
        <i className={`material-icons ${css.sidebarIcon} ${css.active}`}>
          upload
        </i>
      </div>
      <div className={css.cardNone} onClick={() => navigate("/activity")}>
        <h4>Seção 4 - Definindo metas</h4>
      </div>
      <div className={css.cardNone}>
        <i className={`material-icons ${css.sidebarIcon} ${css.active}`}>
          upload
        </i>
      </div>
      <div className={css.cardNone} onClick={() => navigate("/activity")}>
        <h4>Seção 3 - Buscando um Sentido</h4>
      </div>
      <div className={css.upIcon}>
        <i className={`material-icons ${css.sidebarIcon} ${css.active}`}>
          upload
        </i>
      </div>
      <div className={css.cardDisable} onClick={() => navigate("/activity")}>
        <h4>Seção 2 - Conhecendo sua personalidade</h4>
      </div>
      <div className={css.upIcon}>
        <i className={`material-icons ${css.sidebarIcon} ${css.active}`}>
          upload
        </i>
      </div>
      <div className={css.card} onClick={() => navigate("/wordcloud")}>
        <h4>Seção 1 - Nuvem de palavras</h4>
      </div>

      {/* <div className={css.card} onClick={() => navigate("/section1")}>
        <h4>Seção 3 - Buscando um Sentido</h4>
      </div>
      <div className={css.card} onClick={() => navigate("/section1")}>
        <h4>Seção 4 - Definindo metas</h4>
      </div>
      <div className={css.card} onClick={() => navigate("/section1")}>
        <h4>Seção 5 - Meus problemas</h4>
      </div>
      <div className={css.card} onClick={() => navigate("/section1")}>
        <h4>Seção 6 - Plano de ação</h4>
      </div> */}

      {/* <div className={css.card}>
        <h4>Jogo de memória com ...</h4>
      </div> */}
    </div>
  );
};
