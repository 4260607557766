import React from "react";
import HTMLReactParser from "html-react-parser";

import css from "../Home/UserPage.module.css";

export const Move = () => {
  return (
    <section className="containerContent">
      <div className={css.activeMove}>Move</div>
    </section>
  );
};
