import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import css from "./UserPage.module.css";
import { UserContext } from "../../Context/UserContext";
import { Head } from "../../Utils/Head";

export const Favorites = () => {
  const { userData } = useContext(UserContext);
  const navigate = useNavigate();

  const filter = () => {
    return null;
  };

  if (userData) {
    return (
      <div className={css.container}>
        <section>
          <Head title="Favoritos" description={"Favoritos - Mairini"} />

          <div className={css.myActivities}>
            <div
              className={`${css.feature} ${css.featureMind}`}
              onClick={() => filter()}
            >
              <i className={`material-icons ${css.sidebarIcon}`}>
                radio_button_unchecked
              </i>
              <p>MIND</p>
            </div>
            <div
              className={`${css.feature} ${css.featureMove}`}
              onClick={() => filter()}
            >
              <i className={`material-icons ${css.sidebarIcon}`}>
                change_history
              </i>
              <p>MOVE</p>
            </div>
            <div
              className={`${css.feature} ${css.featureNutri}`}
              onClick={() => filter()}
            >
              <i className={`material-icons ${css.sidebarIcon}`}>
                check_box_outline_blank
              </i>
              <p>NUTRI</p>
            </div>
          </div>

          <div className={css.card}>
            <h4>Unidade 0 - Folhas ao vento</h4>
          </div>

          <div className={css.card}>
            <h4>Unidade 1 - Conhecendo sua personalidade</h4>
          </div>

          <div className={css.card}>
            <h4>Unidade 2 - Buscando um sentido</h4>
          </div>

          <div className={css.card}>
            <h4>Unidade 3 - Melhorando o sono</h4>
          </div>

          <div className={css.card}>
            <h4>Unidade 4 - Quebrando as regras</h4>
          </div>

          {/* <div className={css.card} onClick={() => navigate("/myappointments")}>
          <h4>Minhas Consultas</h4>
          <table>
            <thead>
              <tr>
                <th>Dia</th>
                <th>Hora</th>
                <th>Profissional</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {appointments.map((app) => (
                <tr key={app.id}>
                  <td>{dayjs(app.dtStart).format("DD/MM/YY")}</td>
                  <td>{dayjs(app.dtStart).format("HH:mm")}</td>
                  <td>{app.proName}</td>
                  <td
                    className={
                      app.status === "confirmado" ? css.green : css.red
                    }
                  >
                    {app.status}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div> */}
        </section>
      </div>
    );
  } else {
    return (
      <div className={css.container}>
        <div className={css.title}>
          <button className={css.cvBtnIn} onClick={() => navigate("/login")}>
            Criar um perfil !
          </button>
        </div>
      </div>
    );
  }
};
