import React, { useEffect, useContext } from "react";
import { UserContext } from "../../Context/UserContext";

export const Logout = () => {
  const { userLogout } = useContext(UserContext);
  useEffect(() => {
    return userLogout;
  }, [userLogout]);

  return <div>Logout</div>;
};
