import React, { useContext, useEffect, useState } from "react";

import css from "./ProfilePage.module.css";
import { UserContext } from "../../Context/UserContext";
import { Input } from "../Forms/Input";
import { updateUserFirebase } from "../../Utils/firebaseUser";
import { useNavigate } from "react-router-dom";

export const ActionPlan = () => {
  const { userData, setUserData } = useContext(UserContext);
  const [act1, setAct1] = useState("");
  const [act2, setAct2] = useState("");
  const [act3, setAct3] = useState("");
  const [act4, setAct4] = useState("");
  const [act5, setAct5] = useState("");
  const [act6, setAct6] = useState("");
  const [act7, setAct7] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (userData) {
      userData.listActions && setAct1(userData.listActions[0]);
      userData.listActions && setAct2(userData.listActions[1]);
      userData.listActions && setAct3(userData.listActions[2]);
      userData.listActions && setAct4(userData.listActions[3]);
      userData.listActions && setAct5(userData.listActions[4]);
      userData.listActions && setAct6(userData.listActions[5]);
      userData.listActions && setAct7(userData.listActions[6]);
    }
  }, [userData]);

  function handleSubmit() {
    const listActions = [act1, act2, act3, act4, act5, act6, act7];
    userData.actionPlan = listActions;

    setUserData(userData);
    updateUserFirebase(userData);
    navigate("/profile");
  }

  if (userData) {
    return (
      <div className={css.container}>
        <form>
          <h4>Lista de Ações</h4>
          <Input
            type="text"
            name="act1"
            placeholder="Ação 1"
            label="Segunda"
            value={act1}
            required
            className={css.inputText}
            onChange={(e) => {
              setAct1(e.target.value);
            }}
            autoComplete="off"
          />
          <Input
            type="text"
            name="act2"
            placeholder="Ação 2"
            label="Terça"
            value={act2}
            required
            className={css.inputText}
            onChange={(e) => {
              setAct2(e.target.value);
            }}
            autoComplete="off"
          />
          <Input
            type="text"
            name="act3"
            placeholder="Ação 3"
            label="Quarta"
            value={act3}
            required
            className={css.inputText}
            onChange={(e) => {
              setAct3(e.target.value);
            }}
            autoComplete="off"
          />
          <Input
            type="text"
            name="act4"
            placeholder="Ação 4"
            label="Quinta"
            value={act4}
            required
            className={css.inputText}
            onChange={(e) => {
              setAct4(e.target.value);
            }}
            autoComplete="off"
          />
          <Input
            type="text"
            name="act4"
            placeholder="Ação 5"
            label="Sexta"
            value={act5}
            required
            className={css.inputText}
            onChange={(e) => {
              setAct5(e.target.value);
            }}
            autoComplete="off"
          />
          <Input
            type="text"
            name="act4"
            placeholder="Ação 6"
            label="Sábado"
            value={act6}
            required
            className={css.inputText}
            onChange={(e) => {
              setAct6(e.target.value);
            }}
            autoComplete="off"
          />
          <Input
            type="text"
            name="act4"
            placeholder="Ação 7"
            label="Domingo"
            value={act7}
            required
            className={css.inputText}
            onChange={(e) => {
              setAct7(e.target.value);
            }}
            autoComplete="off"
          />
          <div className={css.footer}>
            <button className="saveButton" onClick={() => handleSubmit()}>
              Salvar
            </button>
          </div>
        </form>
      </div>
    );
  } else {
    return <div>Loading...</div>;
  }
};
