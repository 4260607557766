import React from "react";
import { Link, useLocation } from "react-router-dom";

import css from "./Sidebar.module.css";
import { UserContext } from "../../Context/UserContext";
import { ReactComponent as Logo } from "../../assets/mairini-logo.svg";

export const Sidebar = () => {
  const { userData } = React.useContext(UserContext);
  const { pathname } = useLocation();

  return (
    <div className={css.sidebar}>
      <div className={css.sidebarWrapper}>
        <div className={css.sidebarMenu}>
          <div className={css.logo}>
            <Link
              className={css.logo}
              to={userData ? "/" : "/start"}
              aria-label="Mairini - Home"
            >
              <Logo />
              Mairini
            </Link>
          </div>
          <ul className={css.sidebarList}>
            {pathname === "/" || pathname === "/start" ? (
              <Link to={userData ? "/" : "/start"} className={css.link}>
                <li className={`${css.sidebarListItem} ${css.active}`}>
                  <i
                    className={`material-icons ${css.sidebarIcon} ${css.active}`}
                  >
                    today
                  </i>
                  Hoje
                </li>
              </Link>
            ) : (
              <Link to={userData ? "/" : "/start"} className={css.link}>
                <li className={`${css.sidebarListItem}`}>
                  <i className={`material-icons ${css.sidebarIcon}`}>today</i>
                  Hoje
                </li>
              </Link>
            )}

            {pathname.split("/")[1] === "schedule" ? (
              <Link to="/schedule" className={css.link}>
                <li className={`${css.sidebarListItem} ${css.active}`}>
                  <i
                    className={`material-icons ${css.sidebarIcon} ${css.active}`}
                  >
                    calendar_view_month
                  </i>
                  Semana
                </li>
              </Link>
            ) : (
              <Link to="/schedule" className={css.link}>
                <li className={css.sidebarListItem}>
                  <i className={`material-icons ${css.sidebarIcon}`}>
                    calendar_view_month
                  </i>
                  Semana
                </li>
              </Link>
            )}

            {pathname === "/explore" ? (
              <Link to={"/explore"} className={css.link}>
                <li className={`${css.sidebarListItem} ${css.active}`}>
                  <i
                    className={`material-icons ${css.sidebarIcon} ${css.active}`}
                  >
                    explore
                  </i>
                  Explorar
                </li>
              </Link>
            ) : (
              <Link to={"/explore"} className={css.link}>
                <li className={`${css.sidebarListItem}`}>
                  <i className={`material-icons ${css.sidebarIcon}`}>explore</i>
                  Explorar
                </li>
              </Link>
            )}

            {pathname === "/profile" ? (
              <Link to="/profile" className={css.link}>
                <li className={`${css.sidebarListItem} ${css.active}`}>
                  <i
                    className={`material-icons ${css.sidebarIcon} ${css.active}`}
                  >
                    person
                  </i>
                  {/* {userData ? userData.displayName : "Visitante"} */}
                  Perfil
                </li>
              </Link>
            ) : (
              <Link to="/profile" className={css.link}>
                <li className={css.sidebarListItem}>
                  <i className={`material-icons ${css.sidebarIcon}`}>person</i>
                  {/* {userData ? userData.displayName : "Visitante"} */}
                  Perfil
                </li>
              </Link>
            )}

            <hr className={css.divider} />

            {
              <Link to="/contact" className={css.link}>
                <li
                  className={
                    pathname === "/contact"
                      ? `${css.sidebarListItem} ${css.active}`
                      : css.sidebarListItem
                  }
                >
                  <i
                    className={
                      pathname === "/contact"
                        ? `material-icons ${css.sidebarIcon} ${css.active}`
                        : `material-icons ${css.sidebarIcon}`
                    }
                  >
                    call
                  </i>
                  Contato
                </li>
              </Link>
            }

            {userData ? (
              <Link to="/account" className={css.link}>
                <li
                  className={
                    pathname === "/account"
                      ? `${css.sidebarListItem} ${css.active}`
                      : css.sidebarListItem
                  }
                >
                  <i
                    className={
                      pathname === "/account"
                        ? `material-icons ${css.sidebarIcon} ${css.active}`
                        : `material-icons ${css.sidebarIcon}`
                    }
                  >
                    settings
                  </i>
                  Conta
                </li>
              </Link>
            ) : (
              <li className={`${css.sidebarListItem} ${css.disabled}`}>
                <i
                  className={`material-icons ${css.sidebarIcon} ${css.disabled}`}
                >
                  settings
                </i>
                Conta
              </li>
            )}

            {userData ? (
              <Link to="/logout" className={css.link}>
                <li className={css.sidebarListItem}>
                  <i className={`material-icons ${css.sidebarIcon}`}>logout</i>
                  Sair
                </li>
              </Link>
            ) : (
              <Link to="/login" className={css.link}>
                <li className={css.sidebarListItem}>
                  <i className={`material-icons ${css.sidebarIcon}`}>login</i>
                  Entrar
                </li>
              </Link>
            )}
          </ul>
        </div>

        {/* <div
          className={`copyright ${
            userData.staff ? css.sidebarMenu : css.copyright
          } `}
        > */}
        <div className={`copyright ${css.copyright} `}>mairini.com © 2024</div>
      </div>
    </div>
  );
};
