import React from "react";
import HTMLReactParser from "html-react-parser";

import css from "../Home/UserPage.module.css";

export const MyMove = () => {
  return (
    <section className="containerContent">
      <h4>Minhas Práticas</h4>
      <div className={css.activeMove}>Move</div>
    </section>
  );
};
