export const PiCDBr = [
  "Eu geralmente sou uma pessoa ansiosa.",
  "Tendo a agir impulsivamente.",
  "Eu prefiro me manter afastado de outras pessoas.",
  "Minha raiva me faz brigar com as pessoas.",
  "Gasto muito tempo organizando e fazendo planos.",
  "Quando as coisas não saem como o planejado na minha vida, isso me incomoda bastante.",
  "Eu não sou uma pessoa muito responsável.",
  "As pessoas dizem que eu não demonstro meus sentimentos.",
  "Eu me divirto com os problemas dos outros.",
  "Eu não sou uma pessoa de correr riscos.",
  "Meu humor muda frequentemente durante a semana.",
  "Não sou uma pessoa muito organizada.",
  "Sou quieto(a) na presença de outras pessoas.",
  "Eu consigo facilmente convencer as pessoas a fazerem o que eu quero.",
  "Penso cuidadosamente nas coisas antes de agir.",
  "Quando as coisas não estão indo bem para mim, fico mais nervoso(a) do que a maioria das pessoas.",
  "Tomo decisões precipitadas.",
  "Não me sinto próximo de ninguém.",
  "Sou muito mais competitivo(a) do que outras pessoas.",
  "As pessoas pensam que sou perfeccionista.",
  "Depois de algum problema, levo muito tempo para voltar ao normal.",
  "Às vezes, não concluo o que deveria fazer e nem dou satisfação disso.",
  "Quase nada afeta o meu humor, nem críticas nem elogios.",
  "Algumas pessoas merecem não ter onde morar.",
  "Eu sempre escolho a opção mais segura.",
  "Meu humor muda sem relação com eventos que acontecem em minha vida.",
  "Eu tendo a ser bastante descuidado(a) e desorganizado(a).",
  "Posso ser descrito(a) como uma pessoa extremamente tímida.",
  "Costumo seduzir pessoas que na verdade não gosto.",
  "Nunca ajo impulsivamente.",
  "As pessoas percebem meu nervosismo.",
  "Gosto de agir primeiro e pensar depois.",
  "As pessoas dizem que sou distante e retraído(a).",
  "Estou sempre pronto(a) para o conflito.",
  "Eu me esforço ao máximo para alcançar a perfeição.",
  "Sou muito sensível a críticas ou insultos.",
  "Quando não estou afim, deixo de cumprir minhas obrigações.",
  "Raramente me sinto animado(a).",
  "Eu não me preocupo em ferir os sentimentos dos outros.",
  "Minha principal prioridade é estar seguro(a) e protegido(a).",
  "Frequentemente alterno entre me sentir feliz e me sentir triste.",
  "Não me esforço para manter um cronograma ou plano.",
  "Eu costumo falar menos do que a maioria das pessoas.",
  "Sou uma pessoa manipuladora.",
  "Todas as minhas decisões são cuidadosamente pensadas.",
  "Muitas vezes sinto medo sem nenhuma razão específica.",
  "Costumo fazer coisas sem pensar.",
  "Eu não me importaria em viver completamente só, sem nenhum contato humano.",
  "Frequentemente entro em conflito com as pessoas.",
  "Sinto muito orgulho em fazer trabalhos de alta qualidade.",
  "Eu me sinto desprotegido(a).",
  "Eu gasto dinheiro com lazer mesmo tendo contas atrasadas.",
  "Eu não sinto emoções tanto quanto as outras pessoas.",
  "Eu seria um bom soldado porque não me importaria em ferir alguém.",
  "Tendo a ser muito cauteloso(a) e cuidadoso(a).",
  "Minhas mudanças de humor são muito mais intensas do que as de outras pessoas.",
  "Não sigo nenhuma ordem ou plano definido quando trabalho em algo.",
  "Sou sempre tímido em situações sociais.",
  "Eu consigo enganar e manipular as pessoas.",
  `Eu amo o lema: "pense antes de agir"`,
];
