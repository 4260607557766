import React from "react";
import HTMLReactParser from "html-react-parser";

import css from "../Home/UserPage.module.css";

export const Nutri = () => {
  return (
    <section className="containerContent">
      <div className={css.activeNutri}>Nutri</div>
    </section>
  );
};
