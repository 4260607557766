import React from "react";
import { Link, useNavigate } from "react-router-dom";

import css from "./Login.module.css";
import { UserContext } from "../../Context/UserContext";
import { Head } from "../../Utils/Head";
import { useForm } from "../../Hooks/useForm";
import { Button } from "../Forms/Button";
import { Input } from "../Forms/Input";
import { Error } from "../../Utils/Error";

export const Submission = () => {
  const { userRegister, loading, error } = React.useContext(UserContext);
  const navigate = useNavigate();
  const name = useForm();
  const email = useForm("email");
  const password = useForm("password");
  const confirmPassword = useForm("confirmPassword");
  const [alertFirst, setAlertFirst] = React.useState("");
  const [alertEmail, setAlertEmail] = React.useState("");
  const [alertValid, setAlertValid] = React.useState("");

  function handleValidation() {
    let formIsValid = true;
    if (!name.value) {
      formIsValid = false;
      setAlertFirst("You need a first name");
    } else {
      setAlertFirst("");
    }
    if (!email.value) {
      formIsValid = false;
      setAlertEmail("You need a email");
    } else {
      setAlertEmail("");
    }
    if (!password.value) {
      formIsValid = false;
      setAlertValid("You need a password");
    } else {
      setAlertValid("");
    }
    return formIsValid;
  }

  async function handleSubmit() {
    // userRegister(name.value, email.value, password.value);
    navigate("/research");
  }

  return (
    <>
      <section className={css.login}>
        <div></div>
        <div className={css.forms}>
          <Head title="Create your Mairini Account" />
          <h1 className="title">Responder relacional no manejo da depressão</h1>
          <div>
            <h4>I. Explicando o projeto</h4>
            <p>
              Texto Texto Texto Texto Texto Texto Texto Texto Texto Texto Texto
              Texto Texto Texto Texto
            </p>
            <h4>II. Quem pode participar</h4>
            <p>
              Texto Texto Texto Texto Texto Texto Texto Texto Texto Texto Texto
              Texto Texto Texto Texto
            </p>
            <h4>CRITÉRIOS DE INCLUSÃO E EXCLUSÃO:</h4>
            <p>
              Texto Texto Texto Texto Texto Texto Texto Texto Texto Texto Texto
              Texto Texto Texto Texto
            </p>
            <p>
              IMPORTANTE: Para que sua inscrição seja concluída, é necessário
              que o formulário seja preenchido até o final da última etapa
              (incluindo o último questionário). O preenchimento do mesmo leva
              em torno de 30 a 40 minutos.
            </p>
          </div>
          <div>
            <h4>TERMO DE CONSENTIMENTO LIVRE E ESCLARECIDO</h4>
            <h4>Nome do projeto:</h4>
            <h4>Nome do pesquisador:</h4>
            <p>
              Texto Texto Texto Texto Texto Texto Texto Texto Texto Texto Texto
              Texto Texto Texto Texto
            </p>
          </div>
          <div>
            <h4>DADOS DO PARTICIPANTE</h4>
            <p>Idade: </p>
            <p>Sexo: </p>
            <p>Escolaridade: </p>
            <p>Ocupação: </p>

            {alertFirst && <p className={css.error}>{alertFirst}</p>}
            {alertEmail && <p className={css.error}>{alertEmail}</p>}
            {alertValid && <p className={css.error}>{alertValid}</p>}
            <form
              className="form"
              onSubmit={(e) => {
                e.preventDefault();
                // handleValidation() && handleSubmit();
                handleSubmit();
              }}
            >
              <Input label="Nome completo" type="text" name="name" {...name} />
              <Input label="Email" type="email" name="email" {...email} />
              <Input
                label="Senha"
                type="password"
                name="password"
                {...password}
              />
              <Input
                label="Confirme a Senha"
                type="password"
                name="confirmPassword"
                {...confirmPassword}
              />
              {loading ? (
                <Button disabled>Carregando...</Button>
              ) : (
                <Button>Submeter</Button>
              )}
              <Error error={error} />
            </form>
          </div>
          <p>
            já possui um cadastro?{" "}
            <Link className="textLink" to="/login">
              Entrar
            </Link>
          </p>
        </div>
      </section>
    </>
  );
};
