import React, { useState, useEffect } from "react";

import { getProfessionalsFirebase } from "../../Utils/firebaseUser";
import { CardPro } from "../Appointment/CardPro";

export const Professionals = () => {
  const [professionals, setProfessionals] = useState([]);

  useEffect(() => {
    getProfessionalsFirebase().then(function (result) {
      if (result[0]) {
        setProfessionals(result);
      } else {
        console.log("no professionals");
      }
    });
  }, []);

  return (
    <div>
      <h1>Profissionais</h1>
      {professionals[0] ? (
        professionals.map((pro) => (
          <div key={pro.id}>
            <CardPro userName={pro.displayName} userId={pro.id} />
          </div>
        ))
      ) : (
        <p>Não foram encontrados profissionais</p>
      )}
    </div>
  );
};
