import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { getUserByIdFirebase } from "../../Utils/firebaseUser";

export const ProPage = () => {
  const params = useParams();
  const [selectedUser, setSelectedUser] = useState([]);

  useEffect(() => {
    getUserByIdFirebase(params.id).then(function (result) {
      setSelectedUser(result[0]);
    });
  }, []);

  return (
    <div>
      <h2>{selectedUser.displayName}</h2>
      {selectedUser.category === "doctor" && <p>Médico</p>}
      <button
        className="defaultButton"
        onClick={() => alert("médico adicionado")}
      >
        Adicionar Médico
      </button>
    </div>
  );
};
