import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import css from "./Pillar.module.css";
import { UserContext } from "../../Context/UserContext";
import {
  getPracticesNameFirebase,
  getPracticesFirebase,
} from "../../Utils/firebaseClinic";
import { Head } from "../../Utils/Head";

export const Type = () => {
  const { userData } = useContext(UserContext);
  const params = useParams();
  const navigate = useNavigate();
  const [practiceListName, setPracticeListName] = useState([]);
  const [practiceList, setPracticeList] = useState([]);

  useEffect(() => {
    getPracticesNameFirebase(params.pillar).then(function (result) {
      if (result) {
        setPracticeListName(result[`${params.pillar}Practices`]);
      }
    });
    getPracticesFirebase(params.pillar).then(function (result) {
      if (result) {
        setPracticeList(result);
      }
    });
  }, [params]);

  // EXCLUIR OU MELHORAR !!!
  // useEffect(() => {
  //   let newList = [];
  //   for (let index = 0; index < practiceListName.length; index++) {
  //     getPracticesFirebase(params.pillar).then(function (result) {
  //       if (result) {
  //         newList.push(result[practiceListName[index].slug]);
  //         setPracticeList(newList);
  //       } else {
  //         newList.push([]);
  //         setPracticeList(newList);
  //       }
  //     });
  //   }
  // }, [practiceListName, params]);

  if (userData && practiceListName[0]) {
    return (
      <div className="containerContent">
        <Head
          title={params.pillar + " practice"}
          description={params.pillar + " - Mairimed"}
        />
        <div className={css.title}>
          {params.pillar === "mind" ? (
            <i className={`material-icons`}>radio_button_unchecked</i>
          ) : params.pillar === "move" ? (
            <i className={`material-icons`}>change_history</i>
          ) : (
            <i className={`material-icons`}>check_box_outline_blank</i>
          )}
          <h4>{params.pillar}</h4>
        </div>
        <div className={css.title}>
          <p onClick={() => navigate("/mairini/" + params.pillar)}>Leitura</p>
          {params.pillar === "mind" ? (
            <p className={css.activeMind}>Pratica</p>
          ) : params.pillar === "move" ? (
            <p className={css.activeMove}>Pratica</p>
          ) : (
            <p className={css.activeNutri}>Pratica</p>
          )}
        </div>
        <div>
          {practiceListName.map((practice, i) => (
            <React.Fragment key={practice.slug}>
              <hr className={css.divider} align="left" />
              <div
                className={css.featureCard}
                onClick={() =>
                  navigate(
                    `/mairini/${params.pillar}/practice/${practice.slug}`
                  )
                }
              >
                <img className={css.featureImg} alt="" src={practice.image} />
                <div>
                  <h4>{practice.title}</h4>
                  {/* <small>{practiceList[practice].length} praticas</small> */}
                </div>
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
    );
  } else {
    return <div>Loading...</div>;
  }
};
