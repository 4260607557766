export const VLQ = [
  "Relações familiares (não contando as relações com o cônjuge/companheiro ou com os filhos).",
  "Casamento/companheiro/relacionamento afetivo",
  "Ser pai ou mãe (ou exercer esse papel)",
  "Amizades/relações sociais",
  "Trabalho/atividade laboral",
  "Educação/aprendizagem",
  "Lazer/Bem-estar",
  "Espiritualidade/sentido de vida/religião",
  "Vida em sociedade/cidadania (direitos e deveres sociais)",
  "Autocuidado físico (alimentação, exercício/atividade física, descansar/dormir)",
];
