import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import css from "./ProfilePage.module.css";
import { UserContext } from "../../Context/UserContext";
import { Input } from "../Forms/Input";
import { updateUserFirebase } from "../../Utils/firebaseUser";

export const ListProblems = () => {
  const { userData, setUserData } = useContext(UserContext);
  const [problem1, setProblem1] = useState("");
  const [problem2, setProblem2] = useState("");
  const [problem3, setProblem3] = useState("");
  const [problem4, setProblem4] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (userData) {
      userData.listProblems && setProblem1(userData.listProblems[0]);
      userData.listProblems && setProblem2(userData.listProblems[1]);
      userData.listProblems && setProblem3(userData.listProblems[2]);
      userData.listProblems && setProblem4(userData.listProblems[3]);
    }
  }, [userData]);

  function handleSubmit() {
    const listProblems = [problem1, problem2, problem3, problem4];
    userData.listProblems = listProblems;

    setUserData(userData);
    updateUserFirebase(userData);
    navigate("/profile");
  }

  if (userData) {
    return (
      <div className={css.container}>
        <form>
          <h4>Lista de Problemas</h4>
          <Input
            type="text"
            name="problem1"
            placeholder="Problema 1"
            label="Problema 1"
            value={problem1}
            required
            className={css.inputText}
            onChange={(e) => {
              setProblem1(e.target.value);
            }}
            autoComplete="off"
          />
          <Input
            type="text"
            name="problem2"
            placeholder="Problema 2"
            label="Problema 2"
            value={problem2}
            required
            className={css.inputText}
            onChange={(e) => {
              setProblem2(e.target.value);
            }}
            autoComplete="off"
          />
          <Input
            type="text"
            name="problem3"
            placeholder="Problema 3"
            label="Problema 3"
            value={problem3}
            required
            className={css.inputText}
            onChange={(e) => {
              setProblem3(e.target.value);
            }}
            autoComplete="off"
          />
          <Input
            type="text"
            name="problem4"
            placeholder="Problema 4"
            label="Problema 4"
            value={problem4}
            required
            className={css.inputText}
            onChange={(e) => {
              setProblem4(e.target.value);
            }}
            autoComplete="off"
          />
          <div className={css.footer}>
            <button className="saveButton" onClick={() => handleSubmit()}>
              Salvar
            </button>
          </div>
        </form>
      </div>
    );
  } else {
    return <div>Loading...</div>;
  }
};
