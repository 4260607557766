import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import css from "./ProfilePage.module.css";
import { UserContext } from "../../Context/UserContext";
import { Input } from "../Forms/Input";
import { updateUserFirebase } from "../../Utils/firebaseUser";

export const ProfileData = () => {
  const { userData, setUserData } = useContext(UserContext);
  const [birthDate, setBirthDate] = useState(userData.birthDate);
  const [age, setAge] = useState(userData.age);
  const [social, setSocial] = useState(userData.social);
  const [education, setEducatoion] = useState(userData.education);
  const navigate = useNavigate();

  function handleSubmit() {
    userData.birthDate = birthDate;
    userData.age = age;
    userData.social = social;
    userData.education = education;

    setUserData(userData);
    updateUserFirebase(userData);
    navigate("/profile");
  }

  return (
    <div className={css.container}>
      <form>
        <h4>Dados do perfil</h4>
        <Input
          type="text"
          name="birthDate"
          placeholder="Data de Nascimento"
          label="Data de Nascimento"
          value={birthDate}
          required
          className={css.inputText}
          onChange={(e) => {
            setBirthDate(e.target.value);
          }}
          autoComplete="off"
        />
        <Input
          type="text"
          name="age"
          placeholder="Idade"
          label="Idade"
          value={age}
          required
          className={css.inputText}
          onChange={(e) => {
            setAge(e.target.value);
          }}
          autoComplete="off"
        />
        <Input
          type="text"
          name="social"
          placeholder="Estado social"
          label="Estado social"
          value={social}
          required
          className={css.inputText}
          onChange={(e) => {
            setSocial(e.target.value);
          }}
          autoComplete="off"
        />
        <Input
          type="text"
          name="education"
          placeholder="Educação"
          label="Educação"
          value={education}
          required
          className={css.inputText}
          onChange={(e) => {
            setEducatoion(e.target.value);
          }}
          autoComplete="off"
        />
        <div className={css.footer}>
          <button className="saveButton" onClick={() => handleSubmit()}>
            Salvar
          </button>
        </div>
      </form>
    </div>
  );
};
