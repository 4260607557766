import React, { useState, useContext } from "react";
import dayjs from "dayjs";

import css from "./Appointments.module.css";
import { UserContext } from "../../Context/UserContext";
import { ClinicContext } from "../../Context/ClinicContext";
import OutsideAlerter from "../../Utils/OutsideAlerter";
import { CardPro } from "./CardPro";
import {
  createAppointmentFirebase,
  updateAppointmentFirebase,
} from "../../Utils/firebaseClinic";
import { newId } from "../../Utils/newId";

export const Appointment = () => {
  const { professionals } = useContext(ClinicContext);
  const { userData } = useContext(UserContext);
  const { appointments, setAppointments, daySelected, timeSelected } =
    useContext(ClinicContext);
  const [proSelected, setProSelected] = useState([]);
  const [newAppointmentModal, setNewAppointmentModal] = useState(false);
  const [appointmentsModal, setAppointmentsModal] = useState(false);
  const [selectedApp, setSelectedApp] = useState(false);

  function handleAppointment() {
    const timeArr = timeSelected.split(":");
    const appointDay = new Date(daySelected);
    appointDay.setHours(timeArr[0]);
    appointDay.setMinutes(timeArr[1]);

    let newAppointment = {
      id: newId(),
      userId: userData.id,
      userName: userData.displayName,
      userEmail: userData.email,
      description:
        proSelected.displayName +
        ` | ${dayjs(daySelected).format(
          "DD/MM/YY"
        )} | ${timeSelected} | não confirmado`,
      proName: proSelected.displayName,
      proEmail: proSelected.email,
      day: dayjs(daySelected).format("DD/MM/YY"),
      dtStart: appointDay.valueOf(),
      dtEnd: appointDay.valueOf() + 1800000,
      status: "não confirmado",
      location: "teleatendimento",
      geo: "",
      categories: "",
    };
    alert("nova consulta solicitada");
    createAppointmentFirebase(newAppointment);
    setAppointments([...appointments, newAppointment]);
  }

  function handleUpdateAppointment() {
    let updatedApp = {
      id: selectedApp.id,
      userId: selectedApp.userId,
      userName: selectedApp.userName,
      userEmail: selectedApp.userEmail,
      description:
        selectedApp.proName +
        ` | ${dayjs(selectedApp.dtStart).format("DD/MM/YY")} | ${dayjs(
          selectedApp.dtStart
        ).format("HH:mm")} | cancelado pelo usuário`,
      proName: selectedApp.proName,
      proEmail: selectedApp.proEmail,
      day: selectedApp.day,
      dtStart: selectedApp.dtStart,
      dtEnd: selectedApp.dtEnd,
      status: "cancelado pelo usuário",
      location: selectedApp.location,
      geo: selectedApp.geo,
      categories: selectedApp.categories,
    };
    alert("cancelamento de consulta solicitado");
    updateAppointmentFirebase(updatedApp);
    let updatedAppointments = [...appointments];
    updatedAppointments = updatedAppointments.map((app) =>
      app.id === updatedApp.id ? updatedApp : app
    );
    setAppointments([...updatedAppointments]);
  }

  return (
    <div className="containerContent">
      <h4>Solicitar Consulta</h4>
      {professionals[0] ? (
        professionals.map((pro) => (
          <div key={pro.id}>
            <CardPro
              pro={pro}
              setAppointmentsModal={setNewAppointmentModal}
              setProSelected={setProSelected}
            />
          </div>
        ))
      ) : (
        <p>Não foram encontrados profissionais</p>
      )}

      <h4>Minhas Consultas</h4>
      {!appointments[0] && <p>sem consultas agendadas</p>}
      {appointments.map((app) => (
        <React.Fragment key={app.id}>
          <div className={css.card} onClick={app.onClick}>
            <p>Dia: {dayjs(app.dtStart).format("DD/MM/YY")}</p>
            <p>Hora: {dayjs(app.dtStart).format("HH:mm")}</p>
            <p>Proffisional: {app.proName}</p>
            <p>
              Status:{" "}
              <span
                className={app.status === "confirmado" ? css.green : css.red}
              >
                {app.status}
              </span>
            </p>
            {app.status !== "cancelado pelo usuário" && (
              <button
                className="defaultButton"
                onClick={() => {
                  setAppointmentsModal(true);
                  setSelectedApp(app);
                }}
              >
                Solicitar cancelamento
              </button>
            )}
          </div>
        </React.Fragment>
      ))}
      {appointmentsModal && (
        <OutsideAlerter setMenuButton={setAppointmentsModal}>
          <div className={css.appointmentModal}>
            <h3>Cancelar consulta?</h3>
            <p>Profissional: {selectedApp.pro}</p>
            <p>Dia: {dayjs(selectedApp.dtStart).format("DD/MM/YY")}</p>
            <p>Hora: {dayjs(selectedApp.dtStart).format("HH:mm")}</p>
            <div>
              <button
                className="deleteButton"
                onClick={() => setAppointmentsModal(false)}
              >
                Não
              </button>
              <button
                className="saveButton"
                onClick={() => {
                  setAppointmentsModal(false);
                  handleUpdateAppointment();
                }}
              >
                Sim
              </button>
            </div>
          </div>
        </OutsideAlerter>
      )}

      {newAppointmentModal && (
        <OutsideAlerter setMenuButton={setNewAppointmentModal}>
          <div className={css.appointmentModal}>
            <h3>Confirmar consulta?</h3>
            <p>Profissional: {proSelected.displayName}</p>
            <p>Dia: {dayjs(daySelected).format("DD/MM/YY")}</p>
            <p>Hora: {timeSelected}</p>
            <div>
              <button
                className="deleteButton"
                onClick={() => setNewAppointmentModal(false)}
              >
                Cancel
              </button>
              <button
                className="saveButton"
                onClick={() => {
                  setNewAppointmentModal(false);
                  handleAppointment();
                }}
              >
                Enviar
              </button>
            </div>
          </div>
        </OutsideAlerter>
      )}
    </div>
  );
};
