import css from "./leftmenu.module.css";

import { Sidebar } from "./Sidebar";
import { SidebarCollapsed } from "./SidebarCollapsed";

export const LeftMenu = (props) => {
  let className = css.container;
  if (!props.isMenuVisible) {
    className = css.collapsed;
  }

  return (
    <div className={className} onMouseLeave={props.onMouseLeave}>
      {props.isMenuVisible ? <Sidebar /> : <SidebarCollapsed />}
    </div>
  );
};
