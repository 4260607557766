import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import css from "./ProfilePage.module.css";
import { PiCDBr } from "../../Utils/PiCDBr";
import { UserContext } from "../../Context/UserContext";
import { updateUserFirebase } from "../../Utils/firebaseUser";
import { Button } from "../Forms/Button";

export const Personality = () => {
  const { userData, setUserData } = useContext(UserContext);
  const [index, setIndex] = useState(0);
  const PiCDBrarr = new Array(60);
  for (let i = 0; i < PiCDBrarr.length; i++) {
    PiCDBrarr[i] = new Array(5).fill(false);
  }
  const [PiCDBrCheck, setPiCDBrCheck] = useState(PiCDBrarr);
  const [completePiCDBr, setCompletePiCDBr] = useState(0);
  const navigate = useNavigate();

  function handleCheckPiCDBr(row, col) {
    let sum = completePiCDBr + 1;
    setCompletePiCDBr(sum);
    let array = [...PiCDBrCheck];
    if (array[row].includes(true)) {
      if (array[row][col]) {
        array[row][col] = false;
        setPiCDBrCheck(array);
      } else {
        let position = array[row].indexOf(true);
        array[row][position] = false;
        array[row][col] = !array[row][col];
        setPiCDBrCheck(array);
      }
    } else {
      array[row][col] = !array[row][col];
      setPiCDBrCheck(array);
    }
  }

  function submmit() {
    let PiCDBrReduced = PiCDBrCheck.map((row) => row.indexOf(true));
    const PiCDBrAnswers = new Array(60).fill("");
    for (let index = 0; index < PiCDBrReduced.length; index++) {
      PiCDBrAnswers[index] = PiCDBr[PiCDBrReduced[index]]
        ? PiCDBr[PiCDBrReduced[index]]
        : "";
    }

    const negativeAffectivity =
      12 +
      PiCDBrReduced[0] +
      PiCDBrReduced[5] +
      PiCDBrReduced[10] +
      PiCDBrReduced[15] +
      PiCDBrReduced[20] +
      PiCDBrReduced[25] +
      PiCDBrReduced[30] +
      PiCDBrReduced[35] +
      PiCDBrReduced[40] +
      PiCDBrReduced[45] +
      PiCDBrReduced[50] +
      PiCDBrReduced[55];
    const disinhibition =
      12 +
      PiCDBrReduced[1] +
      PiCDBrReduced[6] +
      PiCDBrReduced[11] +
      PiCDBrReduced[16] +
      PiCDBrReduced[21] +
      PiCDBrReduced[26] +
      PiCDBrReduced[31] +
      PiCDBrReduced[36] +
      PiCDBrReduced[41] +
      PiCDBrReduced[46] +
      PiCDBrReduced[51] +
      PiCDBrReduced[56];
    const detachment =
      12 +
      PiCDBrReduced[2] +
      PiCDBrReduced[7] +
      PiCDBrReduced[12] +
      PiCDBrReduced[17] +
      PiCDBrReduced[22] +
      PiCDBrReduced[27] +
      PiCDBrReduced[32] +
      PiCDBrReduced[37] +
      PiCDBrReduced[42] +
      PiCDBrReduced[47] +
      PiCDBrReduced[52] +
      PiCDBrReduced[57];
    const dissociality =
      12 +
      PiCDBrReduced[3] +
      PiCDBrReduced[8] +
      PiCDBrReduced[13] +
      PiCDBrReduced[18] +
      PiCDBrReduced[23] +
      PiCDBrReduced[28] +
      PiCDBrReduced[33] +
      PiCDBrReduced[38] +
      PiCDBrReduced[43] +
      PiCDBrReduced[48] +
      PiCDBrReduced[53] +
      PiCDBrReduced[58];
    const anankastia =
      12 +
      PiCDBrReduced[4] +
      PiCDBrReduced[9] +
      PiCDBrReduced[14] +
      PiCDBrReduced[19] +
      PiCDBrReduced[24] +
      PiCDBrReduced[29] +
      PiCDBrReduced[34] +
      PiCDBrReduced[39] +
      PiCDBrReduced[44] +
      PiCDBrReduced[49] +
      PiCDBrReduced[54] +
      PiCDBrReduced[59];

    const personalityTraits = [
      negativeAffectivity,
      disinhibition,
      detachment,
      dissociality,
      anankastia,
    ];

    userData.personalityTraits = personalityTraits;

    updateUserFirebase(userData);
    setUserData(userData);
    navigate("/profile");
  }

  if (index === 0) {
    return (
      <div className={css.container}>
        <h3>Avaliação da Personalidade</h3>
        <p>
          A seguir estão listadas várias afirmações sobre como você pode se
          sentir ou se comportar. Marque para cada afirmação a resposta que
          melhor descreve você em uma escala de 1 a 5 pontos, em que:
        </p>
        <ul>
          <li>1 = discordo totalmente da afirmação,</li>
          <li>2 = discordo,</li>
          <li>3 = neutro,</li>
          <li>4 = concordo,</li>
          <li>5 = concordo totalmente com a afirmação.</li>
        </ul>
        <p>
          Leia cada item com atenção e marque a resposta que melhor corresponde
          com o seu nível de concordância e discordância. Não há respostas
          certas ou erradas. Descreva-se de forma honesta e exponha suas
          opiniões o mais fielmente possível.
        </p>
        <p>Clique abaixo para iniciar</p>
        <div className={css.footer}>
          <button className="saveButton" onClick={() => setIndex(1)}>
            Iniciar
          </button>
        </div>
      </div>
    );
  } else if (index === 1) {
    return (
      <div className={css.container}>
        <form>
          {PiCDBr.map((question, row) => (
            <React.Fragment key={row}>
              <p>
                {row + 1}. {question}
              </p>

              <label className={css.confirmation}>
                discordo totalmente
                <input
                  type="checkbox"
                  checked={PiCDBrCheck[row][0]}
                  onChange={() => {
                    handleCheckPiCDBr(row, 0);
                  }}
                />
                <span className={css.checkmark}></span>
              </label>
              <label className={css.confirmation}>
                discordo
                <input
                  type="checkbox"
                  checked={PiCDBrCheck[row][1]}
                  onChange={() => {
                    handleCheckPiCDBr(row, 1);
                  }}
                />
                <span className={css.checkmark}></span>
              </label>
              <label className={css.confirmation}>
                neutro
                <input
                  type="checkbox"
                  checked={PiCDBrCheck[row][2]}
                  onChange={() => {
                    handleCheckPiCDBr(row, 2);
                  }}
                />
                <span className={css.checkmark}></span>
              </label>
              <label className={css.confirmation}>
                concordo
                <input
                  type="checkbox"
                  checked={PiCDBrCheck[row][3]}
                  onChange={() => {
                    handleCheckPiCDBr(row, 3);
                  }}
                />
                <span className={css.checkmark}></span>
              </label>
              <label className={css.confirmation}>
                concordo totalmente
                <input
                  type="checkbox"
                  checked={PiCDBrCheck[row][4]}
                  onChange={() => {
                    handleCheckPiCDBr(row, 4);
                  }}
                />
                <span className={css.checkmark}></span>
              </label>
            </React.Fragment>
          ))}
        </form>
        <div className={css.footer}>
          <Button
            onClick={() => {
              submmit();
            }}
          >
            Enviar
          </Button>
        </div>
      </div>
    );
  }
};
