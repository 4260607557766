import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import css from "./ProfilePage.module.css";
import { UserContext } from "../../Context/UserContext";
import { Head } from "../../Utils/Head";

export const ProfilePage = () => {
  const { userData } = useContext(UserContext);
  const navigate = useNavigate();
  const traits = [
    "Afetividade Negativa",
    "Desinibição",
    "Distanciamento",
    "Dissociabilidade",
    "Anancastia",
  ];

  return (
    <>
      <Head title="Profile" description={"Profile - Mairini"} />
      {userData ? (
        <div className={css.profile}>
          <div className={css.profile1}>
            <i
              className={`material-icons ${css.cardIcon}`}
              onClick={() => navigate("/data")}
            >
              edit
            </i>
            <i className={`material-icons ${css.userIcon}`}>account_circle</i>
            <h4>{userData.displayName}</h4>
            <p>DN: {userData.birthDate}</p>
            <p>Idade: {userData.age}</p>
            <p>Estado social: {userData.social}</p>
            <p>Educação: {userData.education}</p>
          </div>
          <div className={css.profile2}>
            <i
              className={`material-icons ${css.cardIcon}`}
              onClick={() => navigate("/actionplan")}
            >
              edit
            </i>
            <h4>Plano de ação</h4>
            {userData.actionPlan && (
              <>
                <p>Segunda</p>
                <ul>
                  <li>{userData.actionPlan[0]}</li>
                </ul>
                <p>Terça</p>
                <ul>
                  <li>{userData.actionPlan[1]}</li>
                </ul>
                <p>Quarta</p>
                <ul>
                  <li>{userData.actionPlan[2]}</li>
                </ul>
                <p>Quinta</p>
                <ul>
                  <li>{userData.actionPlan[3]}</li>
                </ul>
                <p>Sexta</p>
                <ul>
                  <li>{userData.actionPlan[4]}</li>
                </ul>
                <p>Sábado</p>
                <ul>
                  <li>{userData.actionPlan[5]}</li>
                </ul>
                <p>Domingo</p>
                <ul>
                  <li>{userData.actionPlan[6]}</li>
                </ul>
              </>
            )}
          </div>
          <div className={css.profile3}>
            <i
              className={`material-icons ${css.cardIcon}`}
              onClick={() => navigate("/personality")}
            >
              edit_note
            </i>
            <h4>Personalidade</h4>
            {userData.personalityTraits &&
              userData.personalityTraits.map((p, i) => (
                <>
                  <div key={i} className={css.containerBar}>
                    <span className={css.textBar}>{traits[i]}</span>
                    <div
                      className={css.progressBar}
                      style={{
                        width:
                          Math.round(
                            (p * 100) /
                              (userData.personalityTraits[0] +
                                userData.personalityTraits[1] +
                                userData.personalityTraits[2] +
                                userData.personalityTraits[3] +
                                userData.personalityTraits[4])
                          ) + "%",
                      }}
                    >
                      <span>
                        {Math.round(
                          (p * 100) /
                            (userData.personalityTraits[0] +
                              userData.personalityTraits[1] +
                              userData.personalityTraits[2] +
                              userData.personalityTraits[3] +
                              userData.personalityTraits[4])
                        )}
                      </span>
                    </div>
                  </div>
                </>
              ))}
          </div>
          <div className={css.profile4}>
            <i
              className={`material-icons ${css.cardIcon}`}
              onClick={() => navigate("/listproblems")}
            >
              edit
            </i>
            <h4>Lista de Problemas</h4>
            {userData.listProblems &&
              userData.listProblems.map((p, i) => (
                <ul key={i}>
                  <li>{p}</li>
                </ul>
              ))}
          </div>
          <div className={css.profile5}>
            <i
              className={`material-icons ${css.cardIcon}`}
              onClick={() => navigate("/values")}
            >
              edit_note
            </i>
            <h4>Valores</h4>
            {/* <div className={css.wheel}>
              <div className={css.wheel1}></div>
              <div className={css.wheel2}></div>
              <div className={css.wheel3}></div>
              <div className={css.wheel4}></div>
            </div> */}

            {userData.listValues && (
              <>
                <p>Relações familiares: {userData.listValues[0]}</p>
                <p>Companheiro afetivo: {userData.listValues[1]}</p>
                <p>Ser pai ou mãe: {userData.listValues[2]}</p>
                <p>Amizades: {userData.listValues[3]}</p>
                <p>Trabalho: {userData.listValues[4]}</p>
                <p>Educação: {userData.listValues[5]}</p>
                <p>Lazer e Bem-estar: {userData.listValues[6]}</p>
                <p>Espiritualidade: {userData.listValues[7]}</p>
                <p>Cidadania: {userData.listValues[8]}</p>
                <p>Autocuidado: {userData.listValues[9]}</p>
              </>
            )}
          </div>
          <div className={css.profile6}>
            <i
              className={`material-icons ${css.cardIcon}`}
              onClick={() => navigate("/listgoals")}
            >
              edit
            </i>
            <h4>Lista de Metas</h4>
            {userData.listGoals &&
              userData.listGoals.map((p, i) => (
                <ul key={i}>
                  <li>{p}</li>
                </ul>
              ))}
          </div>
        </div>
      ) : (
        <div className={css.container}>
          <div className={css.title}>
            <button className={css.cvBtnIn} onClick={() => navigate("/login")}>
              Criar um perfil !
            </button>
          </div>
        </div>
      )}
    </>
  );
};
