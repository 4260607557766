import React, { useContext, useEffect, useState } from "react";
import dayjs from "dayjs";

import css from "./Schedule.module.css";
import { UserContext } from "../../Context/UserContext";
import { getWeek } from "../../Utils/getDate";
import { Head } from "../../Utils/Head";
import OutsideAlerter from "../../Utils/OutsideAlerter";
import { Input } from "../Forms/Input";
import {
  createScheduleFirebase,
  getPracticesFirebase,
} from "../../Utils/firebaseClinic";

export const Schedule = () => {
  const { user, userData } = useContext(UserContext);
  const [mindPractices, setMindPractices] = useState(false);
  const [movePractices, setMovePractices] = useState(false);
  const [nutriPractices, setNutriPractices] = useState(false);
  const [taskModal, setTaskModal] = useState(false);
  const [selectedTask, setSelectedTask] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(false);
  const [taskName, setTaskName] = useState("");
  const [pillar, setPillar] = useState("mind");
  const [pillarModal, setPillarModal] = useState(false);
  const [task1, setTask1] = useState("");
  const [task2, setTask2] = useState("");
  const [task3, setTask3] = useState("");
  const [task4, setTask4] = useState("");
  const [task5, setTask5] = useState("");
  const [task6, setTask6] = useState("");
  const [task7, setTask7] = useState("");
  const currentWeek = getWeek();
  const [h05h07, seth05h07] = useState(new Array(7).fill(""));
  const [h07h09, seth07h09] = useState(new Array(7).fill(""));
  const [h09h11, seth09h11] = useState(new Array(7).fill(""));
  const [h11h13, seth11h13] = useState(new Array(7).fill(""));
  const [h13h15, seth13h15] = useState(new Array(7).fill(""));
  const [h15h17, seth15h17] = useState(new Array(7).fill(""));
  const [h17h19, seth17h19] = useState(new Array(7).fill(""));
  const [h19h21, seth19h21] = useState(new Array(7).fill(""));
  const [h21h23, seth21h23] = useState(new Array(7).fill(""));
  const periodArray = [
    "h05h07",
    "h07h09",
    "h09h11",
    "h11h13",
    "h13h15",
    "h15h17",
    "h17h19",
    "h19h21",
    "h21h23",
  ];

  useEffect(() => {
    if (userData) {
      seth05h07(userData.schedule.h05h07);
      seth07h09(userData.schedule.h07h09);
      seth09h11(userData.schedule.h09h11);
      seth11h13(userData.schedule.h11h13);
      seth13h15(userData.schedule.h13h15);
      seth15h17(userData.schedule.h15h17);
      seth17h19(userData.schedule.h17h19);
      seth19h21(userData.schedule.h19h21);
      seth21h23(userData.schedule.h21h23);
      getPracticesFirebase("mind").then(function (result) {
        if (result) {
          setMindPractices(result);
        }
      });
      getPracticesFirebase("move").then(function (result) {
        if (result) {
          setMovePractices(result);
        }
      });
      getPracticesFirebase("nutri").then(function (result) {
        if (result) {
          setNutriPractices(result);
        }
      });
    }
  }, [user]);

  useEffect(() => {
    if (!taskModal) {
      setTaskName("");
    }
  }, [taskModal]);

  function getCurrentDayClass(day) {
    return day.format("DD-MM-YY") === dayjs().format("DD-MM-YY")
      ? css.currentDay
      : "";
  }

  function handleTimeByDay(day) {
    const dayPor = dayjs(day).format("ddd");
    switch (dayPor) {
      case "Sun":
        return "Dom";
      case "Mon":
        return "Seg";
      case "Tue":
        return "Ter";
      case "Wed":
        return "Qua";
      case "Thu":
        return "Qui";
      case "Fri":
        return "Sex";
      case "Sat":
        return "Sáb";
      default:
        return;
    }
  }

  function handleSaveSchedule() {
    const objTask = {
      title: taskName,
      pillar: pillar,
      tasks: [task1, task2, task3, task4, task5, task6, task7],
    };
    let newArr = [];

    switch (taskModal) {
      case "Inicio da Manhã":
        newArr = h05h07;
        newArr[selectedIndex] = objTask;
        seth05h07([...newArr]);
        break;
      case "Meio da Manhã":
        newArr = h07h09;
        newArr[selectedIndex] = objTask;
        seth07h09([...newArr]);
        break;
      case "Fim da Manhã":
        newArr = h09h11;
        newArr[selectedIndex] = objTask;
        seth09h11([...newArr]);
        break;
      case "Inicio da Tarde":
        newArr = h11h13;
        newArr[selectedIndex] = objTask;
        seth11h13([...newArr]);
        break;
      case "Meio da Tarde":
        newArr = h13h15;
        newArr[selectedIndex] = objTask;
        seth13h15([...newArr]);
        break;
      case "Fim da Tarde":
        newArr = h15h17;
        newArr[selectedIndex] = objTask;
        seth15h17([...newArr]);
        break;
      case "Inicio da Noite":
        newArr = h17h19;
        newArr[selectedIndex] = objTask;
        seth17h19([...newArr]);
        break;
      case "Meio da Noite":
        newArr = h19h21;
        newArr[selectedIndex] = objTask;
        seth19h21([...newArr]);
        break;
      case "Fim da Noite":
        newArr = h21h23;
        newArr[selectedIndex] = objTask;
        seth21h23([...newArr]);
        break;

      default:
        break;
    }

    const scheduleArray = [
      h05h07,
      h07h09,
      h09h11,
      h11h13,
      h13h15,
      h15h17,
      h17h19,
      h19h21,
      h21h23,
    ];
    // console.log(scheduleArray);
    createScheduleFirebase(user.uid, periodArray, scheduleArray);
  }

  return (
    // <div className="containerContent">
    <div className={css.container}>
      <Head title="Schedule" description="Schedule - Mairini" />
      <div className={css.month}>
        <span className={css.cell_title}></span>

        {currentWeek.map((day, i) => (
          <span className={css.cell_title} key={i}>
            {handleTimeByDay(day)}{" "}
            <span className={`${css.dayNumber} ${getCurrentDayClass(day)}`}>
              {dayjs(day).format("DD")}
            </span>
          </span>
        ))}

        <span className={css.cell_title}>Manhã</span>
        {h05h07.map((task, idx) => (
          <span
            className={`${css.day_name}`}
            key={idx}
            onClick={() => {
              setTaskModal(`Inicio da Manhã`);
              setSelectedTask(mindPractices);
              task ? setPillar(task.pillar) : setPillar("mind");
              task && setTaskName(task.title);
              task && setTask1(task.tasks[0]);
              task && setTask2(task.tasks[1]);
              task && setTask3(task.tasks[2]);
              task && setTask4(task.tasks[3]);
              task && setTask5(task.tasks[4]);
              task && setTask6(task.tasks[5]);
              task && setTask7(task.tasks[6]);
              setSelectedIndex(idx);
            }}
          >
            {task.title}
          </span>
        ))}

        <span className={css.cell_title}>Manhã</span>
        {h07h09.map((task, idx) => (
          <span
            className={css.day_name}
            key={idx}
            onClick={() => {
              setTaskModal(`Meio da Manhã`);
              setSelectedTask(mindPractices);
              task ? setPillar(task.pillar) : setPillar("mind");
              task && setTaskName(task.title);
              task && setTask1(task.tasks[0]);
              task && setTask2(task.tasks[1]);
              task && setTask3(task.tasks[2]);
              task && setTask4(task.tasks[3]);
              task && setTask5(task.tasks[4]);
              task && setTask6(task.tasks[5]);
              task && setTask7(task.tasks[6]);
              setSelectedIndex(idx);
            }}
          >
            {task.title}
          </span>
        ))}

        <span className={css.cell_title}>Manhã</span>
        {h09h11.map((task, idx) => (
          <span
            className={css.day_name}
            key={idx}
            onClick={() => {
              setTaskModal(`Fim da Manhã`);
              setSelectedTask(mindPractices);
              task ? setPillar(task.pillar) : setPillar("mind");
              task && setTaskName(task.title);
              task && setTask1(task.tasks[0]);
              task && setTask2(task.tasks[1]);
              task && setTask3(task.tasks[2]);
              task && setTask4(task.tasks[3]);
              task && setTask5(task.tasks[4]);
              task && setTask6(task.tasks[5]);
              task && setTask7(task.tasks[6]);
              setSelectedIndex(idx);
            }}
          >
            {task.title}
          </span>
        ))}

        <span className={css.cell_title}>Tarde</span>
        {h11h13.map((task, idx) => (
          <span
            className={css.day_name}
            key={idx}
            onClick={() => {
              setTaskModal(`Inicio da Tarde`);
              setSelectedTask(mindPractices);
              task ? setPillar(task.pillar) : setPillar("mind");
              task && setTaskName(task.title);
              task && setTask1(task.tasks[0]);
              task && setTask2(task.tasks[1]);
              task && setTask3(task.tasks[2]);
              task && setTask4(task.tasks[3]);
              task && setTask5(task.tasks[4]);
              task && setTask6(task.tasks[5]);
              task && setTask7(task.tasks[6]);
              setSelectedIndex(idx);
            }}
          >
            {task.title}
          </span>
        ))}

        <span className={css.cell_title}>Tarde</span>
        {h13h15.map((task, idx) => (
          <span
            className={css.day_name}
            key={idx}
            onClick={() => {
              setTaskModal(`Meio da Tarde`);
              setSelectedTask(mindPractices);
              task ? setPillar(task.pillar) : setPillar("mind");
              task && setTaskName(task.title);
              task && setTask1(task.tasks[0]);
              task && setTask2(task.tasks[1]);
              task && setTask3(task.tasks[2]);
              task && setTask4(task.tasks[3]);
              task && setTask5(task.tasks[4]);
              task && setTask6(task.tasks[5]);
              task && setTask7(task.tasks[6]);
              setSelectedIndex(idx);
            }}
          >
            {task.title}
          </span>
        ))}

        <span className={css.cell_title}>Tarde</span>
        {h15h17.map((task, idx) => (
          <span
            className={css.day_name}
            key={idx}
            onClick={() => {
              setTaskModal(`Fim da Tarde`);
              setSelectedTask(mindPractices);
              task ? setPillar(task.pillar) : setPillar("mind");
              task && setTaskName(task.title);
              task && setTask1(task.tasks[0]);
              task && setTask2(task.tasks[1]);
              task && setTask3(task.tasks[2]);
              task && setTask4(task.tasks[3]);
              task && setTask5(task.tasks[4]);
              task && setTask6(task.tasks[5]);
              task && setTask7(task.tasks[6]);
              setSelectedIndex(idx);
            }}
          >
            {task.title}
          </span>
        ))}

        <span className={css.cell_title}>Noite</span>
        {h17h19.map((task, idx) => (
          <span
            className={css.day_name}
            key={idx}
            onClick={() => {
              setTaskModal(`Inicio da Noite`);
              setSelectedTask(mindPractices);
              task ? setPillar(task.pillar) : setPillar("mind");
              task && setTaskName(task.title);
              task && setTask1(task.tasks[0]);
              task && setTask2(task.tasks[1]);
              task && setTask3(task.tasks[2]);
              task && setTask4(task.tasks[3]);
              task && setTask5(task.tasks[4]);
              task && setTask6(task.tasks[5]);
              task && setTask7(task.tasks[6]);
              setSelectedIndex(idx);
            }}
          >
            {task.title}
          </span>
        ))}

        <span className={css.cell_title}>Noite</span>
        {h19h21.map((task, idx) => (
          <span
            className={css.day_name}
            key={idx}
            onClick={() => {
              setTaskModal(`Meio da Noite`);
              setSelectedTask(mindPractices);
              task ? setPillar(task.pillar) : setPillar("mind");
              task && setTaskName(task.title);
              task && setTask1(task.tasks[0]);
              task && setTask2(task.tasks[1]);
              task && setTask3(task.tasks[2]);
              task && setTask4(task.tasks[3]);
              task && setTask5(task.tasks[4]);
              task && setTask6(task.tasks[5]);
              task && setTask7(task.tasks[6]);
              setSelectedIndex(idx);
            }}
          >
            {task.title}
          </span>
        ))}

        <span className={css.cell_title}>Noite</span>
        {h21h23.map((task, idx) => (
          <span
            className={css.day_name}
            key={idx}
            onClick={() => {
              setTaskModal(`Fim da Noite`);
              setSelectedTask(mindPractices);
              task ? setPillar(task.pillar) : setPillar("mind");
              task && setTaskName(task.title);
              task && setTask1(task.tasks[0]);
              task && setTask2(task.tasks[1]);
              task && setTask3(task.tasks[2]);
              task && setTask4(task.tasks[3]);
              task && setTask5(task.tasks[4]);
              task && setTask6(task.tasks[5]);
              task && setTask7(task.tasks[6]);
              setSelectedIndex(idx);
            }}
          >
            {task.title}
          </span>
        ))}
      </div>

      {taskModal && (
        <div className={css.modalContainer}>
          <div className={css.modal}>
            <header className={css.modalHeader}>
              <span
                onClick={() => setTaskModal(false)}
                className={`material-icons ${css.materialIcon} ${css.materialButton}`}
              >
                close
              </span>
            </header>
            <div className={css.modalBody}>
              <h3>
                {taskModal +
                  ` - ${handleTimeByDay(currentWeek[selectedIndex])} ${dayjs(
                    currentWeek[selectedIndex]
                  ).format("DD")}`}
              </h3>
              <Input
                type="text"
                name="task"
                placeholder="Escreva o nome da atividade..."
                label="Nome da Atividade"
                value={taskName}
                required
                className={css.inputText}
                onChange={(e) => setTaskName(e.target.value)}
                autoComplete="off"
              />
              <p>Pilar: </p>
              <div>
                <label className={css.pillar}>
                  mind
                  <input
                    type="checkbox"
                    checked={pillar === "mind" ? true : false}
                    onChange={() => {
                      setPillar("mind");
                      setSelectedTask(mindPractices);
                    }}
                  />
                  <span className={css.checkmark}></span>
                </label>
                <label className={css.pillar}>
                  move
                  <input
                    type="checkbox"
                    checked={pillar === "move" ? true : false}
                    onChange={() => {
                      setPillar("move");
                      setSelectedTask(movePractices);
                    }}
                  />
                  <span className={css.checkmark}></span>
                </label>
                <label className={css.pillar}>
                  nutri
                  <input
                    type="checkbox"
                    checked={pillar === "nutri" ? true : false}
                    onChange={() => {
                      setPillar("nutri");
                      setSelectedTask(nutriPractices);
                    }}
                  />
                  <span className={css.checkmark}></span>
                </label>
                <label className={css.pillar}>
                  outra
                  <input
                    type="checkbox"
                    checked={pillar === "none" ? true : false}
                    onChange={() => setPillar("none")}
                  />
                  <span className={css.checkmark}></span>
                </label>
              </div>
              <p>Pratica 1: {task1}</p>
              <button
                className="defaultButton"
                onClick={() => setPillarModal("1")}
                disabled={pillar === "none" ? true : false}
              >
                add
              </button>
              <p>Pratica 2: {task2}</p>
              <button
                className="defaultButton"
                onClick={() => setPillarModal("2")}
                disabled={pillar === "none" ? true : false}
              >
                add
              </button>
              <p>Pratica 3: {task3}</p>
              <button
                className="defaultButton"
                onClick={() => setPillarModal("3")}
                disabled={pillar === "none" ? true : false}
              >
                add
              </button>
              <p>Pratica 4: {task4}</p>
              <button
                className="defaultButton"
                onClick={() => setPillarModal("4")}
                disabled={pillar === "none" ? true : false}
              >
                add
              </button>
              <p>Pratica 5: {task5}</p>
              <button
                className="defaultButton"
                onClick={() => setPillarModal("5")}
                disabled={pillar === "none" ? true : false}
              >
                add
              </button>
              <p>Pratica 6: {task6}</p>
              <button
                className="defaultButton"
                onClick={() => setPillarModal("6")}
                disabled={pillar === "none" ? true : false}
              >
                add
              </button>
              <p>Pratica 7: {task7}</p>
              <button
                className="defaultButton"
                onClick={() => setPillarModal("7")}
                disabled={pillar === "none" ? true : false}
              >
                add
              </button>
              <footer className={css.footer}>
                <button
                  className="deleteButton"
                  onClick={(e) => {
                    setTaskModal(false);
                  }}
                >
                  Cancelar
                </button>
                <button
                  type="submit"
                  className="saveButton"
                  onClick={(e) => {
                    e.preventDefault();
                    handleSaveSchedule();
                    setTaskModal(false);
                  }}
                >
                  Salvar
                </button>
              </footer>
            </div>
          </div>
        </div>
      )}
      <OutsideAlerter setMenuButton={setPillarModal}>
        {pillarModal && (
          <div className={css.pillarModal}>
            <header className={css.modalHeader}>
              <span
                onClick={() => setPillarModal(false)}
                className={`material-icons ${css.materialIcon} ${css.materialButton}`}
              >
                close
              </span>
            </header>
            <div className={css.modalBody}>
              <h3>
                Pratica {pillarModal} - {pillar}
              </h3>
              {/* <p>Buscar</p> */}
              {Object.keys(selectedTask).map((p, i) => (
                <div className={css.modalPractice} key={i}>
                  <h4>{p}</h4>
                  {Object.values(selectedTask)[i].map((p, idx) => (
                    <button
                      key={idx}
                      onClick={(e) => {
                        e.preventDefault();
                        pillarModal === "1"
                          ? setTask1(p.title)
                          : pillarModal === "2"
                          ? setTask2(p.title)
                          : pillarModal === "3"
                          ? setTask3(p.title)
                          : pillarModal === "4"
                          ? setTask4(p.title)
                          : pillarModal === "5"
                          ? setTask5(p.title)
                          : pillarModal === "6"
                          ? setTask6(p.title)
                          : setPillarModal(false);
                        setPillarModal(false);
                      }}
                    >
                      {p.title}
                    </button>
                  ))}
                </div>
              ))}
              {/* <footer className={css.footer}>
                <button
                  type="submit"
                  className="saveButton"
                  onClick={(e) => {
                    e.preventDefault();
                    setTask1("nova " + pillarModal);
                    setPillarModal(false);
                  }}
                >
                  Add
                </button>
              </footer> */}
            </div>
          </div>
        )}
      </OutsideAlerter>
    </div>
  );
};
