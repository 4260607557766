import React from "react";
import { useNavigate } from "react-router-dom";

import css from "../Home/UserPage.module.css";

export const Section1 = () => {
  const navigate = useNavigate();

  return (
    <div className={css.container}>
      <div className={css.title}>
        <i
          className={`material-icons ${css.sidebarIcon} ${css.active}`}
          onClick={() => navigate("/")}
        >
          keyboard_backspace
        </i>
        <h4>Seção 1 - Escolhendo as palavras</h4>
      </div>
      <div className={css.card} onClick={() => navigate("/wordcloud")}>
        <h4>Nuvem de palavras</h4>
      </div>
      <div className={css.cardDisable}>
        <h4>Escolhendo as melhores palavras - Treino</h4>
      </div>
      <div className={css.cardDisable}>
        <h4>Escolhendo as melhores palavras - Teste</h4>
      </div>
      <div className={css.cardDisable}>
        <h4>Aprendendo novas palavras - Treino</h4>
      </div>
      <div className={css.cardDisable}>
        <h4>Aprendendo novas palavras - Teste</h4>
      </div>
    </div>
  );
};
