import React, { useContext, useState, useEffect } from "react";
import dayjs from "dayjs";

import css from "./CardPro.module.css";
import { ClinicContext } from "../../Context/ClinicContext";
import { Button } from "../Forms/Button";
import SmallCalendar from "../Layout/SmallCalendar";

export const CardPro = ({ pro, ...props }) => {
  const { daySelected, timeSelected, setTimeSelected } =
    useContext(ClinicContext);
  const [timeArray, setTimeArray] = useState([]);

  useEffect(() => {
    const dayArray = [];
    for (var i = 1; i <= 24; i++) {
      dayArray.push(i + ":00");
      dayArray.push(i + ":30");
    }
    const timeArr = [];
    let weekDay = dayjs(daySelected).format("d");
    switch (Number(weekDay)) {
      case 0:
        pro.schedSun.forEach((time, i) => {
          if (time === true) {
            timeArr.push(dayArray[i]);
          }
        });
        break;
      case 1:
        pro.schedMon.forEach((time, i) => {
          if (time === true) {
            timeArr.push(dayArray[i]);
          }
        });
        break;
      case 2:
        pro.schedTue.forEach((time, i) => {
          if (time === true) {
            timeArr.push(dayArray[i]);
          }
        });
        break;
      case 3:
        pro.schedWed.forEach((time, i) => {
          if (time === true) {
            timeArr.push(dayArray[i]);
          }
        });
        break;
      case 4:
        pro.schedThu.forEach((time, i) => {
          if (time === true) {
            timeArr.push(dayArray[i]);
          }
        });
        break;
      case 5:
        pro.schedFri.forEach((time, i) => {
          if (time === true) {
            timeArr.push(dayArray[i]);
          }
        });
        break;
      case 6:
        pro.schedSat.forEach((time, i) => {
          if (time === true) {
            timeArr.push(dayArray[i]);
          }
        });
        break;
      default:
        return;
    }

    setTimeArray(timeArr);
  }, [
    daySelected,
    pro.schedSun,
    pro.schedMon,
    pro.schedTue,
    pro.schedWed,
    pro.schedThu,
    pro.schedFri,
    pro.schedSat,
  ]);

  function handleTimeByDay() {
    const day = dayjs(daySelected).format("ddd");
    switch (day) {
      case "Sun":
        return "Domingo";
      case "Mon":
        return "Segunda";
      case "Tue":
        return "Terça";
      case "Wed":
        return "Quarta";
      case "Thu":
        return "Quinta";
      case "Fri":
        return "Sexta";
      case "Sat":
        return "Sábado";
      default:
        return;
    }
  }

  function validate() {
    let isValid = true;
    if (!daySelected) {
      isValid = false;
    }
    if (!timeSelected) {
      isValid = false;
    }
    return isValid;
  }

  return (
    <div className={css.container} onClick={props.onClick}>
      <section>
        <div className={css.id}>
          <div>
            <i className={`material-icons ${css.cardIcon}`}>account_circle</i>
          </div>
          <div className={css.user}>
            <p>{pro.displayName}</p>
            <span>{pro.pro}</span>
          </div>
        </div>
        <div className={css.place}>
          {pro.clinic && <p>Clinica</p>}
          {pro.tele && <p>Teleatendimento</p>}
          <Button
            onClick={
              validate()
                ? () => {
                    props.setAppointmentsModal(true);
                    props.setProSelected(pro);
                  }
                : () => {
                    alert("data ou hora não valida");
                  }
            }
          >
            Solicitar consulta
          </Button>
        </div>
      </section>
      <section className={css.timeSec}>
        <h5>
          Horário {handleTimeByDay()} {dayjs(daySelected).format("DD")}/
          {dayjs(daySelected).format("MM")}
        </h5>
        <div className={css.timeDiv}>
          <div>
            {timeArray[0] ? (
              timeArray.slice(0, 8).map((time, i) => (
                <p
                  key={i}
                  className={time === timeSelected ? css.activeTime : css.time}
                  onClick={() => setTimeSelected(time)}
                >
                  {time}
                </p>
              ))
            ) : (
              <p>Sem horarios para este dia</p>
            )}
          </div>
          <div>
            {timeArray.slice(8, 16).map((time, i) => (
              <p
                key={i}
                className={time === timeSelected ? css.activeTime : css.time}
                onClick={() => setTimeSelected(time)}
              >
                {time}
              </p>
            ))}
          </div>
        </div>
      </section>
      <section>
        <SmallCalendar />
      </section>
    </div>
  );
};
