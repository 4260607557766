import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import css from "./Cloud.module.css";
import { UserContext } from "../../Context/UserContext";
import Chart from "./Chart";
import { Head } from "../../Utils/Head";
import { newId } from "../../Utils/newId";
import { CloudModel } from "./CloudModel";

export const Cloud = () => {
  const { userData } = useContext(UserContext);
  const navigate = useNavigate();
  const [cloud, setCloud] = useState({});
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (userData) {
      const wordList = [
        {
          id: 1,
          title: userData.displayName,
          level: 0,
          parentId: null,
          color: "color0",
        },
      ];
      userData.wordCloud.map((obj, i) =>
        wordList.push({
          id: i + 2,
          title: obj.word,
          level: obj.valence,
          color: "color0",
          parentId: 1,
        })
      );

      const updatedCloud = {
        id: 0,
        hide: false,
        userId: "13213",
        userName: "User",
        title: "New cloud",
        comment: "",
        reference: "",
        level: 0,
        parentId: null,
        x: 0,
        y: 0,
        width: 700,
        height: 445,
        zoom: 0.8,
        moveMode: true,
        list: wordList,
      };

      setCloud(updatedCloud);
      setLoading(false);
    }
  }, [userData]);

  const toggleMoveMode = () => {
    const moveMode = !cloud.moveMode;
    const newCloud = { ...cloud };
    newCloud.moveMode = moveMode;
    setCloud(newCloud);
  };

  const ZOOM_FACTOR = 1.1;

  const zoomIn = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const zoom = cloud.zoom * ZOOM_FACTOR;
    const newCloud = { ...cloud };
    newCloud.zoom = zoom;
    setCloud(newCloud);
  };

  const zoomOut = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const zoom = cloud.zoom / ZOOM_FACTOR;
    const newCloud = { ...cloud };
    newCloud.zoom = zoom;
    setCloud(newCloud);
  };

  const zoomReset = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const zoom = 0.8;
    const x = 0;
    const y = 0;
    const newCloud = { ...cloud };
    newCloud.zoom = zoom;
    newCloud.x = x;
    newCloud.y = y;
    setCloud(newCloud);
  };

  const onMouseDown = (e) => {
    if (!cloud.moveMode) {
      return;
    }
    setIsDragging(true);
    const newStartX = cloud.x * 1 + e.clientX;
    setStartX(newStartX);
    const newStartY = cloud.y * 1 + e.clientY;
    setStartY(newStartY);
  };

  const onMouseMove = (e) => {
    if (!isDragging) {
      return;
    }
    e.preventDefault();
    const newCloud = { ...cloud };
    const x = (newCloud.x + startX - e.clientX) * 0.5;
    const y = (newCloud.y + startY - e.clientY) * 0.5;
    newCloud.x = x;
    newCloud.y = y;
    setCloud(newCloud);
  };

  const onMouseUp = () => {
    setIsDragging(false);
  };

  const handleSelected = (id) => {
    console.log(id);
  };

  if (userData && !loading) {
    if (userData.wordCloud[0]) {
      return (
        // <div className={css.cloud}>
        <div>
          <Head title="Cloud" description="Cloud - Mairini" />
          {/* <div className={css.cloud}>
            <h1>Cloud</h1>
            {userData.wordCloud.map((obj, i) => (
              <p key={i} style={{ fontSize: obj.valence }}>
                {obj.word}: {obj.valence}
              </p>
            ))}
          </div> */}

          <Chart
            cloudName={cloud.title}
            cloudId={cloud.id}
            id={cloud.id}
            list={cloud.list}
            onClick={handleSelected}
            x={cloud.x}
            y={cloud.y}
            width={cloud.width}
            height={cloud.height}
            zoom={cloud.zoom}
            onZoomIn={zoomIn}
            onZoomOut={zoomOut}
            onZoomReset={zoomReset}
            onToggleMoveMode={toggleMoveMode}
            onMouseDown={onMouseDown}
            onMouseMove={onMouseMove}
            onMouseUp={onMouseUp}
          />

          {/* <h2>Pontuação: </h2> */}
        </div>
      );
    } else {
      return (
        <div className={css.container}>
          <div className={css.card} onClick={() => navigate("/wordcloud")}>
            <h4>Nuvem de palavras</h4>
          </div>
        </div>
      );
    }
  } else {
    return (
      <div className={css.container}>
        <div className={css.title}>
          <button className={css.cvBtnIn} onClick={() => navigate("/login")}>
            Criar um perfil !
          </button>
        </div>
      </div>
    );
  }
};
