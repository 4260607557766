import React from "react";

import css from "../Home/UserPage.module.css";

export const MyMind = () => {
  return (
    <section className="containerContent">
      <h4>Minhas Práticas</h4>
      <div className={css.activeMind}>Mind</div>
    </section>
  );
};
