import React from "react";
import { Link, useLocation } from "react-router-dom";

import css from "./SidebarCollapsed.module.css";
import { UserContext } from "../../Context/UserContext";
import { ReactComponent as Logo } from "../../assets/mairini-logo.svg";

export const SidebarCollapsed = () => {
  const { userData } = React.useContext(UserContext);
  const { pathname } = useLocation();

  return (
    <div className={css.sidebar}>
      <div className={css.sidebarWrapper}>
        <div className={css.sidebarMenu}>
          <div className={css.logo}>
            <Link className={css.logo} to="/" aria-label="Mairini - Home">
              <Logo />
            </Link>
          </div>
          <ul className={css.sidebarList}>
            {pathname === "/" || pathname === "/start" ? (
              <Link to={userData ? "/" : "/start"} className={css.link}>
                <li className={`${css.sidebarListItem} ${css.active}`}>
                  <i
                    className={`material-icons ${css.sidebarIcon} ${css.active}`}
                  >
                    today
                  </i>
                </li>
              </Link>
            ) : (
              <Link to={userData ? "/" : "/start"} className={css.link}>
                <li className={`${css.sidebarListItem}`}>
                  <i className={`material-icons ${css.sidebarIcon}`}>today</i>
                </li>
              </Link>
            )}

            {pathname.split("/")[1] === "schedule" ? (
              <Link to="/schedule" className={css.link}>
                <li className={`${css.sidebarListItem} ${css.active}`}>
                  <i
                    className={`material-icons ${css.sidebarIcon} ${css.active}`}
                  >
                    calendar_view_month
                  </i>
                </li>
              </Link>
            ) : (
              <Link to="/schedule" className={css.link}>
                <li className={css.sidebarListItem}>
                  <i className={`material-icons ${css.sidebarIcon}`}>
                    calendar_view_month
                  </i>
                </li>
              </Link>
            )}

            {pathname === "/explore" ? (
              <Link to={"/explore"} className={css.link}>
                <li className={`${css.sidebarListItem} ${css.active}`}>
                  <i
                    className={`material-icons ${css.sidebarIcon} ${css.active}`}
                  >
                    explore
                  </i>
                </li>
              </Link>
            ) : (
              <Link to={"/explore"} className={css.link}>
                <li className={`${css.sidebarListItem}`}>
                  <i className={`material-icons ${css.sidebarIcon}`}>explore</i>
                </li>
              </Link>
            )}

            {/* {pathname === "/know" ? (
              <Link to="/know" className={css.link}>
                <li className={`${css.sidebarListItem} ${css.active}`}>
                  <i
                    className={`material-icons ${css.sidebarIcon} ${css.active}`}
                  >
                    route
                  </i>
                </li>
              </Link>
            ) : (
              <Link to="know" className={css.link}>
                <li className={`${css.sidebarListItem}`}>
                  <i className={`material-icons ${css.sidebarIcon}`}>route</i>
                </li>
              </Link>
            )} */}

            {/* {pathname === "/cloud" ? (
              <Link to="/cloud" className={css.link}>
                <li className={`${css.sidebarListItem} ${css.active}`}>
                  <i
                    className={`material-icons ${css.sidebarIcon} ${css.active}`}
                  >
                    cloud
                  </i>
                </li>
              </Link>
            ) : (
              <Link to="/cloud" className={css.link}>
                <li className={css.sidebarListItem}>
                  <i className={`material-icons ${css.sidebarIcon}`}>cloud</i>
                </li>
              </Link>
            )} */}

            {pathname.split("/")[1] === "profile" ? (
              <Link to="/profile" className={css.link}>
                <li className={`${css.sidebarListItem} ${css.active}`}>
                  <i
                    className={`material-icons ${css.sidebarIcon} ${css.active}`}
                  >
                    person
                  </i>
                </li>
              </Link>
            ) : (
              <Link to="/profile" className={css.link}>
                <li className={css.sidebarListItem}>
                  <i className={`material-icons ${css.sidebarIcon}`}>person</i>
                </li>
              </Link>
            )}

            <hr className={css.divider} />

            {userData && (
              <Link to="/account" className={css.link}>
                <li
                  className={
                    pathname === "/account"
                      ? `${css.sidebarListItem} ${css.active}`
                      : css.sidebarListItem
                  }
                >
                  <i
                    className={
                      pathname === "/account"
                        ? `material-icons ${css.sidebarIcon} ${css.active}`
                        : `material-icons ${css.sidebarIcon}`
                    }
                  >
                    call
                  </i>
                </li>
              </Link>
            )}

            {userData && (
              <Link to="/account" className={css.link}>
                <li
                  className={
                    pathname === "/account"
                      ? `${css.sidebarListItem} ${css.active}`
                      : css.sidebarListItem
                  }
                >
                  <i
                    className={
                      pathname === "/account"
                        ? `material-icons ${css.sidebarIcon} ${css.active}`
                        : `material-icons ${css.sidebarIcon}`
                    }
                  >
                    settings
                  </i>
                </li>
              </Link>
            )}

            {userData ? (
              <Link to="/logout" className={css.link}>
                <li className={css.sidebarListItem}>
                  <i className={`material-icons ${css.sidebarIcon}`}>logout</i>
                </li>
              </Link>
            ) : (
              <Link to="/login" className={css.link}>
                <li className={css.sidebarListItem}>
                  <i className={`material-icons ${css.sidebarIcon}`}>login</i>
                </li>
              </Link>
            )}
          </ul>
        </div>
        {/* <div
          className={`copyright ${
            userData.staff ? css.sidebarMenu : css.copyright
          } `}
        > */}
        {/* <div>
          <p>mairini.com</p>
          <p>©2023</p>
        </div> */}
      </div>
    </div>
  );
};
