import React, { useContext, useEffect, useState } from "react";

import css from "../Pillar/Pillar.module.css";
import { UserContext } from "../../Context/UserContext";
import { ClinicContext } from "../../Context/ClinicContext";
import { useNavigate, useParams } from "react-router-dom";
import {
  createTheoryFirebase,
  getTheoryFirebase,
} from "../../Utils/firebaseClinic";
import { Head } from "../../Utils/Head";
import OutsideAlerter from "../../Utils/OutsideAlerter";
import HTMLReactParser from "html-react-parser";

export const Mind = () => {
  const { userData } = useContext(UserContext);
  const { theoryList, setTheoryList } = useContext(ClinicContext);
  const params = useParams();
  const navigate = useNavigate();
  const [showSelectedTheory, setShowSelectedTheory] = useState(false);
  const [selectedTheory, setSelectedTheory] = useState([]);

  useEffect(() => {
    getTheoryFirebase(params.pillar).then(function (result) {
      if (result) {
        setTheoryList(result[`${params.pillar}TheoryList`]);
      } else {
        setTheoryList([]);
      }
    });
  }, [params]);

  function handleDelete(post) {
    let updatedList = [...theoryList];
    updatedList = updatedList.filter((p) => p.slug !== post.slug);
    createTheoryFirebase(params.pillar, updatedList);
    setTheoryList(updatedList);
    alert(post.title + " deleted");
  }

  if (userData) {
    return (
      <div className="containerContent">
        <Head title="Mind" description={"Mind - Mairini"} />
        <div className={css.activeMind}>
          <i className={`material-icons`}>radio_button_unchecked</i>
          <h4>Mind</h4>
        </div>
        <div className={css.title}>
          <p className={css.activeMind}>Leitura</p>
          <p onClick={() => navigate(`/mairini/mind/practice`)}>Pratica</p>
        </div>
        <div>
          {userData.staff && (
            <button
              className="defaultButton"
              onClick={() =>
                navigate("/newpost", {
                  state: {
                    list: theoryList,
                    pillar: params.pillar,
                    theory: "theory",
                  },
                })
              }
            >
              New
            </button>
          )}
        </div>
        <div>
          {theoryList.map((item) => (
            <React.Fragment key={item.slug}>
              <hr className={css.divider} align="left" />
              <div className={css.myActivities}>
                {userData.staff && (
                  <button
                    className="defaultButton"
                    onClick={() =>
                      navigate("/editpost", {
                        state: {
                          list: theoryList,
                          pillar: params.pillar,
                          theory: "theory",
                          item: item,
                        },
                      })
                    }
                  >
                    Edit
                  </button>
                )}
                {userData.staff && (
                  <button
                    className="redButton"
                    onClick={() => handleDelete(item)}
                  >
                    Delete
                  </button>
                )}
              </div>
              <div
                className={css.featureCard}
                onClick={() => {
                  setShowSelectedTheory(true);
                  setSelectedTheory(item);
                }}
              >
                <img className={css.featureImg} alt="" src={item.image} />
                <div>
                  <h4>{item.title}</h4>
                  <small>
                    Tempo de leitura aproximado:{" "}
                    {Math.round(item.content.length / 450)} min
                  </small>
                </div>
              </div>
            </React.Fragment>
          ))}
        </div>
        {showSelectedTheory && (
          <OutsideAlerter setMenuButton={setShowSelectedTheory}>
            <div className={css.modal}>
              <header className={css.modalHeader}>
                <span
                  onClick={() => setShowSelectedTheory(false)}
                  className={`material-icons ${css.materialIcon} ${css.materialButton}`}
                >
                  close
                </span>
              </header>
              <div className={css.modalBody}>
                <h3>{selectedTheory.title}</h3>
                {selectedTheory.image && (
                  <img
                    alt=""
                    src={selectedTheory.image}
                    className={css.modalImg}
                  />
                )}
                {HTMLReactParser(selectedTheory.content)}
              </div>
            </div>
          </OutsideAlerter>
        )}
      </div>
    );
  } else {
    return <div>Loading...</div>;
  }
};
