import React, { useContext, useState } from "react";

import css from "./Navbar.module.css";
import logoName from "../../assets/logoName.png";
import { Link, useLocation } from "react-router-dom";
import { UserContext } from "../../Context/UserContext";

export const Navbar = () => {
  const { user, userLogout } = useContext(UserContext);
  const { pathname } = useLocation();
  const [nav, setNav] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 50) {
      setNav(true);
    } else {
      setNav(false);
    }
  };

  window.addEventListener("scroll", changeBackground);

  return (
    <div>
      <nav className={nav ? `${css.nav} ${css.active}` : css.nav}>
        <a href="/" className={css.logo}>
          <img src={logoName} alt="" />
        </a>
        <input type="checkbox" id="menuBtn" className={css.menuBtn} />
        <label className={css.menuIcon} htmlFor="menuBtn">
          <span className={css.navIcon}></span>
        </label>
        <ul className={css.menu}>
          <li>
            <Link to="/home#home" className={css.btnIn}>
              Início
            </Link>
          </li>
          <li>
            <Link to="/home#proposal" className={css.btnIn}>
              Proposta
            </Link>
          </li>
          <li>
            <Link className={css.btnIn} to="/about">
              Sobre
            </Link>
          </li>
          <li>
            <Link to="/home#contact" className={css.btnIn}>
              Contato
            </Link>
          </li>
          {/* <li>
            <Link to="/start" className={css.btnIn}>
              Blog
            </Link>
          </li> */}
          {/* <li>
            {!user ? (
              <Link to="/login" className={css.btnIn}>
                Entrar
              </Link>
            ) : (
              <Link onClick={userLogout}>Sair</Link>
            )}
          </li> */}
        </ul>
      </nav>
    </div>
  );
};
