import React from "react";

import css from "./connection.module.css";

function Connection(props) {
  const getElement = (element) => {
    let className = css.connection;
    if (element.level === 0) {
      return null;
    }
    className += " " + css[element.color];
    const DX = element.isLeftSide ? -40 : 40;
    const dx = element.x - element.px;
    const dy = element.y - element.py;
    const d = `M ${element.px} ${element.py} c ${DX} 0, ${
      dx - DX
    } ${dy}, ${dx} ${dy}`;
    return (
      <React.Fragment key={element.id}>
        <path key={element.id} d={d} className={css.connection} />
      </React.Fragment>
    );
  };
  return props.list.map((element) => getElement(element));
}

export default Connection;
