import React from "react";

import css from "./HomePage.module.css";
import logoName from "../../assets/logoName.png";
import RochaPerfil from "../../assets/RochaPerfil.png";
import whatsappLogo from "../../assets/whatsappLogo.png";
import { Head } from "../../Utils/Head";
import { Navbar } from "../Layout/Navbar";
import featureImage from "../../assets/feature_1.png";
import featureImage1 from "../../assets/feature_2.png";
import featureImage2 from "../../assets/feature_3.png";
import aboutImage from "../../assets/psychiatry.png";
import aboutImage1 from "../../assets/behaviour.png";
import { useNavigate } from "react-router-dom";
import ScrollToAnchor from "../../Utils/ScrollToAnchor";

export const HomePage = () => {
  const navigate = useNavigate();

  return (
    <>
      <Head title="Home" description="Home - Mairimed" />
      <ScrollToAnchor />
      <div className={css.container}>
        <Navbar />
        <div className={css.main} id="home">
          <div className={css.name}>
            <img src={RochaPerfil} alt="" />
            <h1>Thiago Rocha</h1>
            <button
              className={css.cvBtn}
              onClick={() =>
                window.open(
                  "https://api.whatsapp.com/send/?phone=556192492842&text=Ol%C3%A1+gostaria+de+marcar+uma+consulta&type=phone_number&app_absent=0",
                  "_blank"
                )
              }
            >
              Agendar atendimento
            </button>
          </div>
        </div>

        <div className={css.features} id="proposal">
          <h1>PROPOSTA</h1>
          <p>SOBRE A PROPOSTA DO MAIRINI</p>
          <p>Atendimento personalizado</p>
          <p>
            Uso de ferramentas da web baseadas em abordagens cognitivo
            comportamentais
          </p>
        </div>

        <div className={css.features} id="leituras">
          <h1>ABORDAGENS</h1>
          <div className={css.aContainer}>
            <div className={css.featuresBox}>
              <div className={css.featuresImg}>
                <img src={featureImage} alt="" />
              </div>
              <div className={css.featuresText}>
                <h2>
                  Mudança de <br />
                  Comportamento
                </h2>
                <p></p>
              </div>
            </div>
            <div className={css.featuresBox}>
              <div className={css.featuresImg}>
                <img src={featureImage1} alt="" />
              </div>
              <div className={css.featuresText}>
                <h2>Farmacoterapia</h2>
                <p></p>
              </div>
            </div>
            <div className={css.featuresBox}>
              <div className={css.featuresImg}>
                <img src={featureImage2} alt="" />
              </div>
              <div className={css.featuresText}>
                <h2>Psicoterapia</h2>
                <p></p>
              </div>
            </div>
          </div>
        </div>

        <div className={css.contact} id="contact">
          <h1>CONTATO</h1>
          <div className={css.contactData}>
            <ul className={css.sidebarList}>
              <li className={`${css.sidebarListItem}`}>
                <img src={whatsappLogo} width={"20px"} alt="" />
                <p>(61) 9249-2842</p>
              </li>
              <li className={`${css.sidebarListItem}`}>
                <i className={`material-icons ${css.sidebarIcon}`}>
                  location_on
                </i>
                <p>
                  SEPS 705/905 sul, <br /> Centro Empresarial Mont Blanc, Sala
                  211
                  <br />
                  Asa Sul. Quintessencia
                </p>
              </li>
              <li className={`${css.sidebarListItem}`}>
                <i className={`material-icons ${css.sidebarIcon}`}>mail</i>
                <p>contact@mairini.com</p>
              </li>
            </ul>
          </div>
          {/* <div>
            <h3>Envie um email</h3>
            <div className={css.contactInput}>
              <input type="text" placeholder="Nome" />
            </div>
            <div className={css.contactInput}>
              <input type="email" placeholder="E-mail" />
            </div>

            <textarea
              className={css.contactArea}
              rows="7"
              type="text"
              placeholder="Escreva sua mensagem"
            />

            <button onClick={() => console.log("email sended")}>Enviar</button>
          </div> */}
        </div>
        <div id={css.footer}>
          <ul>
            <li>
              © 2024
              <br />
              Mairini
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
