import React, { useContext, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import HTMLReactParser from "html-react-parser";

import css from "./Pillar.module.css";
import { UserContext } from "../../Context/UserContext";
import OutsideAlerter from "../../Utils/OutsideAlerter";
import {
  getPracticesFirebase,
  getPracticesNameFirebase,
} from "../../Utils/firebaseClinic";
import { Head } from "../../Utils/Head";

export const Practice = () => {
  const { userData } = useContext(UserContext);
  const params = useParams();
  const navigate = useNavigate();
  const [practiceListName, setPracticeListName] = useState([]);
  const [typeList, setTypeList] = useState([]);
  const [practiceList, setPracticeList] = useState([]);
  const [selectedPractice, setSelectedPractice] = useState([]);
  const [showSelectedPractice, setShowSelectedPractice] = useState(false);

  useEffect(() => {
    getPracticesNameFirebase(params.pillar).then(function (result) {
      if (result) {
        setPracticeListName(result[`${params.pillar}Practices`]);
      }
    });
    getPracticesFirebase(params.pillar).then(function (result) {
      if (result[params.type]) {
        setPracticeList(result[params.type]);
        setTypeList(result);
      }
    });
  }, [params]);

  if (userData && practiceListName[0]) {
    return (
      <div className={`containerContent ${css.content}`}>
        <Head title={params.type} description={params.type + " - Mairimed"} />
        <h4>
          {
            practiceListName[
              practiceListName.map((item) => item.slug).indexOf(params.type)
            ].title
          }
        </h4>
        <p onClick={() => navigate(-1)} className={css.back}>
          voltar
        </p>
        <div>
          {userData.staff && (
            <button
              className="defaultButton"
              onClick={() =>
                navigate("/newpost", {
                  state: {
                    list: practiceList,
                    pillar: params.pillar,
                    theory: "practice",
                    type: params.type,
                    typeList: typeList,
                    typeListName: practiceListName.map((t) => t.slug),
                  },
                })
              }
            >
              New
            </button>
          )}
        </div>
        {practiceList.map((practice) => (
          <React.Fragment key={practice.id}>
            <hr className={css.divider} align="left" />
            <div>
              {userData.staff && (
                <button
                  className="defaultButton"
                  onClick={() =>
                    navigate("/editpost", {
                      state: {
                        list: practiceList,
                        pillar: params.pillar,
                        theory: "practice",
                        practice: params.type,
                        item: practice,
                      },
                    })
                  }
                >
                  Edit
                </button>
              )}
            </div>
            <div
              className={css.featureCard}
              onClick={() => {
                setShowSelectedPractice(true);
                setSelectedPractice(practice);
              }}
            >
              <img
                className={css.featureImg}
                alt=""
                src="https://cdn.sanity.io/images/0vv8moc6/psychtimes/05a2dd97fbedb285c5fd380a411b24847c8dcc11-1000x819.jpg?fit=crop&auto=format"
              />
              <div>
                <h4>{practice.title}</h4>
              </div>
            </div>
          </React.Fragment>
        ))}
        {showSelectedPractice && (
          <OutsideAlerter setMenuButton={setShowSelectedPractice}>
            <div className={css.modal}>
              <header className={css.modalHeader}>
                <span
                  onClick={() => setShowSelectedPractice(false)}
                  className={`material-icons ${css.materialIcon} ${css.materialButton}`}
                >
                  close
                </span>
              </header>
              <div className={css.modalBody}>
                <h3>{selectedPractice.title}</h3>
                {selectedPractice.image && (
                  <img
                    alt=""
                    src={selectedPractice.image}
                    className={css.modalImg}
                  />
                )}
                {HTMLReactParser(selectedPractice.content)}
              </div>
            </div>
          </OutsideAlerter>
        )}
      </div>
    );
  } else {
    return <div>Loading...</div>;
  }
};
