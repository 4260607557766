import React, { useState, useContext, useEffect, useReducer } from "react";
import dayjs from "dayjs";

import { UserContext } from "./UserContext";
import {
  getActFirebase,
  getAppointmentsPatFirebase,
} from "../Utils/firebaseClinic";
import { savedAppointmentsDocDayReducer } from "./clinicReducer";
import { getProfessionalsFirebase } from "../Utils/firebaseUser";

export const ClinicContext = React.createContext();

export const ClinicStorage = ({ children }) => {
  const { userData } = useContext(UserContext);
  const [actToday, setActToday] = useState(false);
  const [theoryList, setTheoryList] = useState([]);
  const [practiceList, setPracticeList] = useState([]);
  const [theoryMind, setTheoryMind] = useState([]);
  const [breathingList, setBreathingList] = useState([]);
  const [stretchingList, setStretchingList] = useState([]);
  const [theoryMove, setTheoryMove] = useState([]);
  const [theoryNutri, setTheoryNutri] = useState([]);
  const [appointmentsDocDay, dispatchAppointmentsDocDay] = useReducer(
    savedAppointmentsDocDayReducer,
    []
  );
  const [smallCalendarMonth, setSmallCalendarMonth] = useState(dayjs().month());
  const [professionals, setProfessionals] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [daySelected, setDaySelected] = useState(dayjs());
  const [timeSelected, setTimeSelected] = useState("");
  const [docSelected, setDocSelected] = useState(false);
  const [showAppointmentModal, setShowAppointmentModal] = React.useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [view, setView] = useState("day");
  const [loadingClinic, setLoadingClinic] = useState(null);

  useEffect(() => {
    getActFirebase().then(function (result) {
      if (result[0]) {
        setActToday(result[0]);
      }
    });

    if (userData) {
      getAppointmentsPatFirebase(userData.email).then(function (result) {
        if (result[0]) {
          let array = result.sort((a, b) =>
            a.dtStart < b.dtStart ? 1 : b.dtStart < a.dtStart ? -1 : 0
          );
          setAppointments(array);
        }
      });
    }
    getProfessionalsFirebase().then(function (result) {
      if (result[0]) {
        setProfessionals(result);
      } else {
        console.log("no professionals");
      }
    });
  }, [userData]);

  return (
    <ClinicContext.Provider
      value={{
        actToday,
        theoryList,
        setTheoryList,
        practiceList,
        setPracticeList,
        theoryMind,
        setTheoryMind,
        breathingList,
        setBreathingList,
        stretchingList,
        setStretchingList,
        theoryMove,
        setTheoryMove,
        theoryNutri,
        setTheoryNutri,
        appointmentsDocDay,
        dispatchAppointmentsDocDay,
        smallCalendarMonth,
        setSmallCalendarMonth,
        daySelected,
        setDaySelected,
        docSelected,
        setDocSelected,
        showAppointmentModal,
        setShowAppointmentModal,
        selectedAppointment,
        setSelectedAppointment,
        view,
        setView,
        loadingClinic,
        setLoadingClinic,
        timeSelected,
        setTimeSelected,
        appointments,
        setAppointments,
        professionals,
        setProfessionals,
      }}
    >
      {children}
    </ClinicContext.Provider>
  );
};
