import React from "react";

import css from "./HomePage.module.css";
import RochaPerfil from "../../assets/RochaPerfil.png";
import { Head } from "../../Utils/Head";
import { Navbar } from "../Layout/Navbar";

export const About = () => {
  return (
    <>
      <Head title="Home" description="Home - Mairimed" />
      <div className={css.container}>
        <Navbar />
        <div className={css.about} id="about">
          <h1>SOBRE</h1>
          <img src={RochaPerfil} alt="" />
          <h1>Thiago Rocha</h1>
          <p>
            Graduação em medicina pela Escola Superior de Ciências da Saúde
            (ESCS). Residência médica em psiquiatria realizada no Hospital de
            Base do Distrito Federal. Atualmente psiquiatra da SES-DF e
            preceptor do programa de residência médica de psiquiatria do
            HSVP/Fepecs. Responsável pelo ambulatório de psicoterapias do HSVP.
            Mestrando em ciências do comportamento pela Universidade de
            Brasília.
          </p>
        </div>
        <div className={css.features}></div>

        <div id={css.footer}>
          <ul>
            <li>
              © 2024
              <br />
              Mairini
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
