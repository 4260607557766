import React, { useContext } from "react";
import { UserContext } from "../../Context/UserContext";

import css from "./HomePage.module.css";
import { Head } from "../../Utils/Head";
import { Navbar } from "../Layout/Navbar";

export const Request = () => {
  const { userData } = useContext(UserContext);

  if (userData) {
    return (
      <>
        <Head title="Home" description="Home - Mairimed" />
        <div className={css.container}>
          <Navbar />
          <div id={css.contact}>
            <div id="contato">
              <p>Olá, {userData.displayName}</p>
              <p>
                O acesso ao Mairini é realizado por meio de plano terapêutico.
              </p>
              <p>
                Você pode solicitar acesso ao Mairini enviando uma mensagem
                abaixo
              </p>
              <p>contact@mairini.com</p>
              <div className={css.contactInput}>
                <input type="text" placeholder="Nome" />
              </div>
              <div className={css.contactInput}>
                <input type="email" placeholder="E-mail" />
              </div>

              <textarea
                className={css.contactArea}
                rows="7"
                type="text"
                placeholder="Escreva sua mensagem"
              />

              <button onClick={() => console.log("email sended")}>
                Enviar
              </button>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <Head title="Home" description="Home - Mairimed" />
        <div className={css.container}>
          <Navbar />
          <div id={css.contact}>
            <div id="contato">
              <p>
                O acesso ao Mairini é realizado por meio de plano terapêutico.
              </p>
              <p>
                Você pode solicitar acesso ao Mairini enviando uma mensagem
                abaixo
              </p>
              <p>contact@mairini.com</p>
              <div className={css.contactInput}>
                <input type="text" placeholder="Nome" />
              </div>
              <div className={css.contactInput}>
                <input type="email" placeholder="E-mail" />
              </div>

              <textarea
                className={css.contactArea}
                rows="7"
                type="text"
                placeholder="Escreva sua mensagem"
              />

              <button onClick={() => console.log("email sended")}>
                Enviar
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
};
